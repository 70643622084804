import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, TextField, Typography} from '@material-ui/core';
import Application, {App} from '../../App';
import API from 'adapters/rest';
import {CircularLoader} from 'shared/components/Loaders/CircularLoader';
import emptyState from 'shared/assets/landingpage-empty-state.svg';
import {Body, InfiniteScroll} from '@cervello/ui-collection';
import {useStyles} from '../../styles';
import AddIcon from '@material-ui/icons/Add';
import debounce from 'lodash.debounce';
import CreateModal from '../../createapp-modal';
// import { useTranslation } from 'react-i18next';

let pagination = {
  nextPage: 1,
  count: 0,
  limit: 10,
};

export default function AllApplications() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [Applications, setApplications] = useState<App[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  // const {t} = useTranslation();
  const classes = useStyles();

  const handleSearch = useCallback(
    debounce(
      (text: string) => {
        setApplications([]);
        setIsLoading(true);
        setSearchKey(text);
        getData(false, text);
      },
      300,
      {
        leading: false,
        trailing: true,
      },
    ),
    [searchKey],
  );

  const getData = useCallback((isPagination = false, searchKey = '') => {
    const url = `/iam/v1/applications?${`limit=10&offset=${
      isPagination ? pagination.nextPage * 10 : 0
    }${`&&name=${searchKey}`}`}`;
    API.get(url)
      .then(response => {
        const {data, page, limit, count} = response.data.result;
        if (isPagination) {
          pagination = {nextPage: page + 1, limit, count};
          setApplications(prevState => [...prevState, ...data]);
        } else {
          pagination = {nextPage: 1, limit, count};
          setApplications(data);
          setIsLoading(false);
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box className={classes.rightColumn}>
        <Box display="flex" justifyContent="space-between" alignItems="end">
          <Typography className={classes.title} style={{color: '#282828', fontSize: '24px'}}>
            {' '}
            My Applications{' '}
          </Typography>
          <Box display="flex">
            <TextField
              placeholder="Search"
              onChange={e => {
                //setApplications([])
                handleSearch(e.target.value);
              }}
              variant="outlined"
              InputProps={{
                className: classes.searchInput,
                style: {borderRadius: '6px'},
              }}
            />
            <Button
              startIcon={<AddIcon />}
              onClick={() => setOpen(true)}
              className={classes.createBtn}
              color="primary"
              variant="contained"
            >
              {' '}
              Create Application
            </Button>
          </Box>
        </Box>

        {isLoading ? (
          <CircularLoader />
        ) : Applications.length > 0 ? (
          <InfiniteScroll
            dataLength={Applications.length}
            next={() => getData(true, searchKey)}
            hasMore={!isLoading && Applications.length < pagination.count}
            loader={<CircularLoader />}
            cardDimension={{
              width: 270,
              height: 290,
            }}
            style={{overflow: 'auto'}}
            height="calc(100vh - 80px)"
            className={classes.scrollable}
          >
            <div
              style={{
                margin: '30px 0px 0px 15px',
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              {Applications.map(app => (
                <Application {...app} key={app.id} />
              ))}
            </div>
          </InfiniteScroll>
        ) : searchKey ? (
          <Box
            mt={5}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            height="calc(100vh - 140px)"
            width="100%"
          >
            <img src={emptyState} style={{width: '225px', height: '230px'}} alt={'empty state'} />

            <Typography className={classes.mt3}>No Applications with this name</Typography>
            <Body variant={'subtitle2'} className={classes.mt1}>
              {'Try another name?'}
            </Body>
          </Box>
        ) : (
          <Box
            mt={5}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            height="calc(100vh - 140px)"
            width="100%"
          >
            <img src={emptyState} style={{width: '225px', height: '230px'}} alt={'empty state'} />

            <Typography className={classes.mt3}>Start creating your first application</Typography>
            <Body variant={'subtitle2'} className={classes.mt1}>
              {'Would you like to create one now?'}
            </Body>
            <Box mt={2}>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={() => {
                  setOpen(true);
                }}
              >
                Create application
              </Button>
            </Box>
          </Box>
        )}
      </Box>

            <CreateModal setOpen={setOpen} open={open} />
        </>
    )
}





















