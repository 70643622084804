import {withStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core';

export default withStyles(theme => ({
  root: {
    '&.secondary': {
      color: theme.palette.secondary.dark,
      '&.light': {
        color: theme.palette.secondary.shades[6],
      },
      '&.MuiButton-contained': {
        background: theme.palette.secondary.shades[6],
        color: theme.palette.common.white,
      },
    },
    '&.darkSecondary': {
      '&.MuiButton-contained': {
        background: theme.palette.secondary.shades[2],
        color: theme.palette.primary.light,
        '&:hover': {
          background: theme.palette.secondary.shades[9],
          color: theme.palette.primary.main,
        },
      },
    },
    '&.mediumSecondary': {
      '&.MuiButton-contained': {
        background: theme.palette.secondary.shades[4],
        color: theme.palette.secondary.dark,
        '&.Mui-disabled': {
          background: theme.palette.secondary.shades[0],
          color: theme.palette.grey[400],
        },
        '&.active': {
          background: theme.palette.secondary.shades[2],
          color: theme.palette.primary.light,
        },
        '&:hover': {
          background: theme.palette.secondary.shades[2],
          color: theme.palette.primary.light,
        },
      },
      '&.bordered': {
        border: `1px solid ${theme.palette.secondary.light}`,
      },
    },
    '&.lightSecondary': {
      color: theme.palette.secondary.shades[5],

      '&:hover': {
        background: theme.palette.secondary.shades[4],
      },

      '&.MuiButton-contained': {
        background: theme.palette.secondary.shades[4],
        color: theme.palette.secondary.shades[5],
      },
    },
    '&.transSecondary': {
      '&.MuiButton-contained': {
        background: theme.palette.secondary.shades[0],
        color: theme.palette.primary.light,
      },
    },
    '&.accent': {
      '&.MuiButton-contained': {
        background: theme.palette.accent.main,
        color: theme.palette.common.white,

        '&.Mui-disabled': {
          opacity: 0.7,
        },

        '&:hover': {
          background: theme.palette.accent.shades[3],
        },
      },
    },
    '&.danger': {
      color: theme.palette.error.main,
      '&.MuiButton-contained': {
        background: theme.palette.error.main,
      },
      '&:hover': {
        background: theme.palette.grey[200],
      },
      '&.Mui-disabled': {
        background: theme.palette.secondary.shades[0],
        color: theme.palette.grey[400],
      },
    },
    '&.lightDanger': {
      color: theme.palette.error.main,
      '&.MuiButton-contained': {
        background: theme.palette.grey[100],
      },
      '&:hover': {
        background: theme.palette.error.shades[0],
      },
      '&.Mui-disabled': {
        background: theme.palette.secondary.shades[0],
        color: theme.palette.grey[400],
      },
    },
    '&.darkDanger': {
      '&.MuiButton-contained': {
        background: theme.palette.error.main,
        color: theme.palette.common.white,
        '&:hover': {
          background: theme.palette.error.dark,
        },
        '&.Mui-disabled': {
          background: theme.palette.secondary.shades[0],
          color: theme.palette.grey[400],
        },
      },
    },
    '&.lower': {
      textTransform: 'unset',
      letterSpacing: 'normal',
    },
    '& + .btn': {
      marginLeft: theme.spacing(2),
      '&.near': {
        marginLeft: theme.spacing(1),
      },
      '&.far': {
        marginLeft: theme.spacing(4),
      },
    },
    '&.nearIcon': {
      '& .MuiButton-endIcon': {
        marginLeft: 2,
      },
      '& .MuiButton-startIcon': {
        marginRight: 2,
      },
    },
    '&.noWrap': {
      whiteSpace: 'nowrap',
    },
    '&.long': {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      minWidth: 140,

      '&.MuiButton-sizeSmall': {
        width: 200,
      },
    },
    '&.rounded': {
      borderRadius: 20,
    },
    '&.small': {
      padding: '2px 10px',
      minWidth: 80,
    },
  },
}))(Button);
