import React, {useCallback, useRef, useState} from 'react';
import clsx from 'clsx';
import {Box, IconButton, Tooltip, Hidden, Popover, Typography} from '@material-ui/core';
import {AccountTree, Apps, DataUsage, Map, MapOutlined} from '@material-ui/icons';
import AppsLogo from 'shared/assets/apps-logo.svg';
import FlowLogo from 'shared/assets/flow-logo.svg';
import ThingsLogo from 'shared/assets/things-logo.svg';
import InsightsLogo from 'shared/assets/insights-logo.svg';
import Application from './Applications';
// import Activities from './Activities';
// import FeedbackComponent from './Feedback';
// import Reports from './Reports';
// import Help from './Help';
import Profile from './Profile';
import {useStyles} from './styles';
import useUserData from '../../../useUserData';
import InitialsAvatar from 'react-initials-avatar';
import {useParams} from 'react-router-dom';
import {useFlags} from 'flagsmith/react';
import TranslateIcon from '@mui/icons-material/Translate';
import i18n from 'i18next';

const icons = {
  // activities: {
  //   name: 'activities',
  //   Icon: TrackChanges,
  //   responsive: true,
  // },
  // reports: {
  //   name: 'reports',
  //   Icon: Equalizer,
  //   responsive: true,
  // },
  // feedback: {
  //   name: 'feedback',
  //   Icon: Feedback,
  //   responsive: false,
  // },
  // help: {
  //   name: 'help',
  //   Icon: HelpOutline,
  //   responsive: false,
  // },
  // application: {
  //   name: 'application',
  //   Icon: Apps,
  //   responsive: true,
  // },
};

export default function Menu() {
  const classes = useStyles();
  const {projectId} = useParams<{projectId: string}>();
  const [activeIcon, setActiveIcon] = React.useState<null | string>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const anchorRef = React.useRef<HTMLElement | null>(null);
  const element = useRef<HTMLDivElement>(null);
  const shouldClose = useRef(true);
  const {user} = useUserData();
  const {REACT_APP_DISPATCHING_URL, REACT_APP_WORKFLOW_URL} = (window as any).env;
  const flags = useFlags(['flow', 'workforce']);
  const [currentLang, setCurrentLang] = useState<string>(
    localStorage.getItem('language') || i18n.language,
  );

  const handleClose = useCallback(() => {
    if (shouldClose.current) {
      anchorRef.current = null;
      setActiveIcon(null);
    }
    shouldClose.current = true;
  }, [shouldClose]);

  const toggleLanguage = useCallback(() => {
    const newLanguage = i18n.language === 'en' ? 'ar' : 'en';
    localStorage.setItem('language', newLanguage);
    setCurrentLang(newLanguage);
    i18n.changeLanguage(newLanguage);
  }, []);

  if (!user) {
    return null;
  }

  return (
    <div ref={element} className={classes.root}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={'center'}
        className={classes.iconsBox}
        flexDirection={'column'}
      >
        <Hidden smUp>
          <IconButton color="primary">
            <DataUsage fontSize="small" />
          </IconButton>
        </Hidden>
        {/*<Tooltip title={'Apps'}>*/}
        {/*  <IconButton*/}
        {/*    className={classes.icon}*/}
        {/*    // color="primary"*/}
        {/*    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {*/}
        {/*      setAnchorEl(event.currentTarget);*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <Apps />*/}
        {/*  </IconButton>*/}
        {/*</Tooltip>*/}
        {/*{Object.values(icons).map(({name, Icon, responsive}) => (*/}
        {/*  <Tooltip title={name} key={name} classes={{tooltip: classes.tooltip}}>*/}
        {/*    <IconButton*/}
        {/*      className={clsx(name, classes.icon, {*/}
        {/*        hide: !responsive,*/}
        {/*      })}*/}
        {/*      color="primary"*/}
        {/*      onClick={({currentTarget}) => {*/}
        {/*        shouldClose.current = name === activeIcon || !activeIcon;*/}
        {/*        setActiveIcon(name);*/}
        {/*        anchorRef.current = currentTarget;*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <Icon fontSize="small" />*/}
        {/*    </IconButton>*/}
        {/*  </Tooltip>*/}
        {/*))}*/}
        {projectId && (
          <>
            {' '}
            {flags['flow']?.enabled && (
              <Tooltip title="Workflow" placement={'right'} classes={{tooltip: classes.tooltip}}>
                <a href={`${REACT_APP_WORKFLOW_URL}/${projectId}`}>
                  <IconButton className={classes.profile} color="primary">
                    <AccountTree />
                  </IconButton>
                </a>
              </Tooltip>
            )}
            {flags['workforce']?.enabled && (
              <Tooltip title="Field force" placement={'right'} classes={{tooltip: classes.tooltip}}>
                <a href={`${REACT_APP_DISPATCHING_URL}/${projectId}`}>
                  <IconButton className={classes.profile} color="primary">
                    <MapOutlined />
                  </IconButton>
                </a>
              </Tooltip>
            )}
          </>
        )}
        <Tooltip
          title={currentLang === 'en' ? 'Translate to Arabic' : 'Translate to English'}
          placement={'right'}
          classes={{tooltip: classes.tooltip}}
        >
          <IconButton color="primary" onClick={toggleLanguage}>
            <Typography className={classes.translateIcon} variant={'h6'}>
              {i18n.language === 'en' ? 'Ar' : 'En'}
            </Typography>
            {/*<TranslateIcon />*/}
          </IconButton>
        </Tooltip>
        <Tooltip title="profile" placement={'right'} classes={{tooltip: classes.tooltip}}>
          <IconButton
            className={classes.profile}
            color="primary"
            onClick={({currentTarget}) => {
              setActiveIcon('profile');
              anchorRef.current = currentTarget;
            }}
          >
            <div className={classes.profileIcon}>
              {/*<img src={user.profilePictureUrl} title="account img" />*/}
              {user.profilePictureUrl ? (
                <img src={user.profilePictureUrl} title="account img" />
              ) : (
                <InitialsAvatar name={user.name} className={classes.initialsAvatar} />
              )}
            </div>
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <Profile
          isOpen={activeIcon === 'profile'}
          anchorEl={anchorRef.current}
          handleClose={handleClose}
        />
        {/*<Application*/}
        {/*  isOpen={activeIcon === icons.application.name}*/}
        {/*  anchorEl={anchorRef.current}*/}
        {/*  handleClose={handleClose}*/}
        {/*  accountId={user.accountId as string}*/}
        {/*/>*/}
        {/*<Help isOpen={activeIcon === icons.help.name} handleClose={handleClose} />*/}
        {/*<FeedbackComponent*/}
        {/*  isOpen={activeIcon === icons.feedback.name}*/}
        {/*  anchorEl={anchorRef.current}*/}
        {/*  handleClose={handleClose}*/}
        {/*/>*/}
        {/*<Reports isOpen={activeIcon === icons.reports.name} handleClose={handleClose} />*/}
        {/*<Activities isOpen={activeIcon === icons.activities.name} handleClose={handleClose} />*/}
        {/*<div*/}
        {/*  className={clsx(classes.menuOverlay, {*/}
        {/*    [classes.menuOverlayOpen]: Boolean(activeIcon),*/}
        {/*  })}*/}
        {/*/>*/}
        <Popover
          id={anchorEl ? 'simple-popover' : undefined}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          classes={{paper: classes.popover}}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexWrap={'wrap'}
          >
            <Box>
              <Box className={clsx(classes.applicationsLogo, 'selected')}>
                <img src={ThingsLogo} className={'svg'} />
              </Box>
              <Typography color={'primary'} className={'txt'}>
                Things
              </Typography>
            </Box>
            <Box>
              <Box className={classes.applicationsLogo}>
                <img src={AppsLogo} className={'svg'} />
              </Box>
              <Typography color={'primary'} className={'txt'}>
                Apps
              </Typography>
            </Box>
            <Box>
              <Box className={classes.applicationsLogo} mt={2}>
                <img src={InsightsLogo} className={'svg'} />
              </Box>
              <Typography color={'secondary'} className={'txt'}>
                Flow
              </Typography>
            </Box>
            <Box>
              <Box className={classes.applicationsLogo} mt={2}>
                <img src={FlowLogo} className={'svg'} />
              </Box>
              <Typography color={'secondary'} className={'txt'}>
                Insights
              </Typography>
            </Box>
          </Box>
        </Popover>
      </Box>
    </div>
  );
}
