import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import {makeStyles} from '@material-ui/core/styles';
import {Close} from '@material-ui/icons';
import {ReferenceObject} from 'popper.js';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginRight: theme.spacing(3),
    zIndex: 1200,
    // [theme.breakpoints.down('xs')]:{
    //   marginRight:0,
    //   width:'100%',
    //   right:0,
    //   left:0
    // }
  },
  paper: {
    paddingTop: theme.spacing(4),
    width: theme.custom.drawerWidth,
  },
  close: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
    color: theme.palette.secondary.dark,
    cursor: 'pointer',
  },
}));

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  anchorEl?: null | ReferenceObject | (() => ReferenceObject);
}

export default function PopupMenu({isOpen, anchorEl, children, handleClose}: Props) {
  const classes = useStyles();

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorEl}
      role={undefined}
      transition
      className={classes.root}
      placement={'right-end'}
    >
      {({TransitionProps, placement}) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper elevation={3} className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <div>
                <div className={classes.close}>
                  <Close onClick={handleClose} />
                </div>
                {children}
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
