import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 40,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    // position: 'fixed',
    bottom: -10,
    overflowX: 'hidden',
    position: 'fixed',
    right: 90,
    // right: theme.direction === 'ltr' ? 30 : 'auto',
    // left: theme.direction === 'ltr' ? 'auto' : 30,
    zIndex: 9,
    minWidth: 60,
    minHeight: 60,

    '& > span': {
      color: 'rgb(0 0 0 /.7)',
      padding: 15,
      borderRadius: '50%',
      position: 'absolute',
      bottom: 0,
      right: 0,
      // right: theme.direction === 'ltr' ? 0 : 'auto',
      // left: theme.direction === 'ltr' ? 'auto' : 0,
      // background: '#f3f3f3',
      background: theme.palette.primary.light,

      '& svg': {
        color: '#fff',
      },
    },
    // '&:hover': {
    //   '& > span': {
    //     color: theme.palette.primary.shades[10],
    //     background: theme.palette.common.white,
    //     '& svg': {
    //       color: 'inherit',
    //     },
    //   },
    // },
    '& .mantine-Popover-dropdown': {
      // right: theme.direction === 'ltr' ? 0 : 'auto !important',
      // left: theme.direction === 'ltr' ? 'auto !important' : 0,
      left: 'auto !important',
      top: 'auto !important',
      bottom: 50,
      position: 'static',
      marginBottom: 40,
      // marginRight: theme.direction === 'ltr' ? 40 : 0,
      marginRight: 40,
      // marginLeft: theme.direction === 'ltr' ? 0 : 40,
      '& .nc-layout-wrapper': {
        background: '#fafafa',
        '& .nc-notifications-list-item.css-1wmhpyn': {
          background: '#ebedef',
        },
      },
      '& .nc-footer': {
        display: 'none',
      },
    },
  },
  item: {
    padding: 15,
    position: 'relative',
    margin: '10px 15px',
    boxShadow: 'rgba(122, 133, 153, 0.25) 0px 5px 15px',
    background: '#fff',
    borderRadius: 7,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontFamily: 'poppins',
    paddingRight: theme.spacing(8),
    // paddingRight: theme.direction === 'ltr' ? theme.spacing(8) : 0,
    // paddingLeft: theme.spacing(8),
    '&.read': {
      background: '#ebedef',
      boxShadow: 'none',
    },

    '&:not(.read):before': {
      content: "''",
      position: 'absolute',
      inset: 0,
      width: 5,
      borderRadius: '7px 0px 0px 7px',
      background: 'rgb(53 124 254)',
    },

    '& .date': {
      color: 'rgb(130, 130, 153)',
      fontSize: 12,
      opacity: 0.5,
      lineHeight: '14.4px',
    },

    '& .alarmAction': {
      width: 10,
      height: 10,
      borderRadius: '50%',
      marginRight: theme.spacing(1),
    },

    '& .text': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },

    '& .icon': {
      fontSize: '1rem',
      color: '#777',
      marginRight: 7,
    },

    '&.notSeen': {
      '&:after': {
        content: "''",
        position: 'absolute',
        width: 10,
        height: 10,
        borderRadius: '50%',
        background: '#154aef',
        right: 20,
        top: '45%',
      },
    },
  },
  link: {
    color: 'inherit',
  },
}));
