import React, {useCallback, useEffect} from 'react';
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config';
import {Router, withRouter, useParams} from 'react-router-dom';
// import Layoutcc from 'modules/Layoutcc';
// import {Autorenew, DevicesOther, Home, TrackChanges, Widgets} from '@material-ui/icons';
// import EqualizerIcon from '@material-ui/icons/Equalizer';
// import {StemPaths} from 'modules/IotPaaS/types';
import {connectSocket} from '../../adapters/websocket';
import {getProject} from './api/Projects';
import useInstanceData from '../useInstanceData';
import Header from '../Layout/Header';
import Loader from 'shared/components/Loaders/GlobalLoader';

function WithLayout(props: RouteConfigComponentProps) {
  const {history, route: {routes = [], sideMenu} = {}} = props;
  const {instance} = useInstanceData();
  const {projectId} = useParams<{projectId: string}>();

  const authenticateProject = useCallback(() => {
    if (!instance?.id) {
      return null;
    }
    getProject(instance.id, projectId).catch(() => {
      // window.location.href = '/';
      history.push('/403');
    });
  }, [instance, projectId, history]);

  useEffect(() => {
    if (projectId) {
      authenticateProject();
      connectSocket(projectId);
    }
  }, [projectId, authenticateProject]);

  return (
    <div>
      {/*<Layoutcc sideMenuItems={sideMenu && BottomItems} showProjectsDropdown={true}>*/}
      {/*<Header*/}
      {/*  // setMenuIsOpen={setMenuIsOpen}*/}
      {/*  // menuIsOpen={menuIsOpen}*/}
      {/*  showProjectsDropdown={false}*/}
      {/*/>*/}
      <React.Suspense fallback={<Loader />}>
        <Router history={history}>{renderRoutes(routes)}</Router>
      </React.Suspense>
      {/*</Layoutcc>*/}
    </div>
  );
}

export default withRouter(WithLayout);
