import React, {useState} from 'react';
import {AccountCircle, Input} from '@material-ui/icons';
import {Box, Typography, Link as DummyLink} from '@material-ui/core';
import PopupMenu from '../PopupMenu';
import {useStyles} from './styles';
import {useCommonStyles} from 'App/themes';
import {ReferenceObject} from 'popper.js';
import useUserData from 'modules/useUserData';
import {useTranslation} from 'react-i18next';
import InitialsAvatar from 'react-initials-avatar';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import {useLocation} from 'react-router-dom';
import InviteUserModal from './InviteUserModal';

interface Props {
  isOpen: boolean;
  anchorEl?: null | ReferenceObject | (() => ReferenceObject);
  handleClose: () => void;
}

export default function Profile({isOpen, anchorEl, handleClose}: Props) {
  const {user, logout, accountManagement} = useUserData();
  const {t} = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);

  if (!user) {
    return null;
  }
  return (
    <>
      <PopupMenu isOpen={isOpen} anchorEl={anchorEl} handleClose={handleClose}>
        <Box display="flex" p={3}>
          <Box className={classes.img}>
            {user.profilePictureUrl ? (
              <img
                src={user.profilePictureUrl}
                title="account img"
                className={commonClasses.imgWidth}
                alt={'Profile Picture'}
              />
            ) : (
              <InitialsAvatar name={user.name} className={classes.initialsAvatar} />
            )}
          </Box>
          <Box ml={4}>
            <Typography variant="body2" className={commonClasses.boldF}>
              {`${user.name}`}
            </Typography>
            <Typography variant="subtitle2">{user.email}</Typography>
          </Box>
        </Box>
        <Box>
          {/*<DummyLink*/}
          {/*  className={classes.link}*/}
          {/*  onClick={() => {*/}
          {/*    if (accountManagement) {*/}
          {/*      return accountManagement();*/}
          {/*    }*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <AccountCircle fontSize="small" className={classes.linkIcon} />*/}
          {/*  <Typography variant="subtitle2" className={commonClasses.capitalizeTxt}>*/}
          {/*    {t('auth.manage_account')}*/}
          {/*  </Typography>*/}
          {/*</DummyLink>*/}

          {location.pathname.includes('/admin') ? (
            <DummyLink
              className={classes.link}
              onClick={() => {
                setOpen(true);
              }}
            >
              <AccountCircle fontSize="small" className={classes.linkIcon} />
              <Typography variant="subtitle2" className={commonClasses.capitalizeTxt}>
                Invite user
              </Typography>
            </DummyLink>
          ) : (
            <></>
          )}

          <DummyLink
            underline="none"
            className={classes.link}
            onClick={() => {
              if (logout) {
                return logout({redirectUri: '/'});
              }
            }}
          >
            <Input fontSize="small" className={classes.linkIcon} />
            <Typography variant="subtitle2" className={commonClasses.capitalizeTxt}>
              {t('auth.sign_out')}
            </Typography>
          </DummyLink>
        </Box>
      </PopupMenu>
      <InviteUserModal setOpen={setOpen} open={open} />
    </>
  );
}
