import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  spinner: {
    margin: '30px auto 0',
    width: '130px',
    textAlign: 'center',
  },
  bounce1: {
    width: '18px',
    height: '18px',
    backgroundColor: 'rgb(2, 175, 254)',
    marginLeft: '9px',
    borderRadius: '100%',
    display: 'inline-block',
    animation: `$bouncedelay 1.4s infinite ease-in both`,

    webkitAnimationDelay: '-0.32s',
    animationDelay: '-0.3s',
  },

  bounce2: {
    width: '18px',
    height: '18px',
    backgroundColor: 'rgb(35, 90, 254)',
    marginLeft: '9px',
    borderRadius: '100%',
    display: 'inline-block',
    animation: `$bouncedelay 1.4s infinite ease-in both`,
    animationDelay: '-0.2s',
  },

  bounce3: {
    width: '18px',
    height: '18px',
    backgroundColor: 'rgb(133, 2, 254)',
    marginLeft: '9px',
    borderRadius: '100%',
    display: 'inline-block',
    animation: `$bouncedelay 1.4s infinite ease-in both`,

    animationDelay: '-0.1s',
  },
  bounce4: {
    width: '18px',
    height: '18px',
    backgroundColor: 'rgb(254, 63, 254)',
    marginLeft: '9px',
    borderRadius: '100%',
    display: 'inline-block',
    animation: `$bouncedelay 1.4s infinite ease-in both`,

    animationDelay: '-0.0s',
  },

  '@keyframes bouncedelay': {
    '0%, 80%, 100%': {
      webkitTransform: 'scale(0)',
      transform: 'scale(0)',
    },
    '40%': {
      webkitTransform: 'scale(1.0)',
      transform: 'scale(1.0)',
    },
  },
}));
