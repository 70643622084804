import React from 'react';
import clsx from 'clsx';
import {Typography, IconButton, Box, Hidden, Link as DummyLink} from '@material-ui/core';
import {Menu} from '@material-ui/icons';
import Suitelogo from 'shared/assets/suite-logo.svg';
import ThingsLogo from 'shared/assets/things.svg';
import logoWhite from 'shared/assets/logo-white.svg';
import {useStyles} from './styles';
import ProjectsSelect from '../ProjectsSelect';
import {Link, useHistory} from 'react-router-dom';

// import {Link} from '@cervello/ui-collection';

interface Props {
  setMenuIsOpen?: Function;
  menuIsOpen: boolean;
  searchIsOpen: boolean;
  showProjectsDropdown: boolean;
}

export default function HeaderLogo({
  setMenuIsOpen,
  menuIsOpen,
  searchIsOpen,
  showProjectsDropdown,
}: Props) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      className={clsx(classes.root, {resSearchIsOpen: searchIsOpen})}
    >
      <Hidden smUp>
        <IconButton
          className={classes.menuButton}
          onClick={() => setMenuIsOpen && setMenuIsOpen(!menuIsOpen)}
        >
          <Menu fontSize="large" />
        </IconButton>
      </Hidden>
      <Box
        // edge="start"
        display={'flex'}
        alignItems={'center'}
        className={classes.homeButton}
        color="inherit"
        aria-label="open drawer"
        mx={'auto'}
        mt={2}
      >
        <Link to="/" target="_parent" className={classes.logoLink}>
          <img alt="" src={Suitelogo} className={classes.logo} />
        </Link>
        {/*<img alt="" src={ThingsLogo} className={classes.logoThings} />*/}
      </Box>
      {/*<Hidden only/>="xs">*/}
      {/*  <div className={classes.title}>*/}
      {/*    <Typography variant="h6" noWrap>*/}
      {/*      CERVELLO*/}
      {/*    </Typography>*/}
      {/*    <Typography variant="overline" noWrap>*/}
      {/*      Things*/}
      {/*    </Typography>*/}
      {/*  </div>*/}
      {/*</Hidden>*/}
      {showProjectsDropdown && (
        <Hidden only="xs">
          <ProjectsSelect />
        </Hidden>
      )}
    </Box>
  );
}
