import React, {useCallback} from 'react';
import clsx from 'clsx';
import {Box, Drawer, IconButton, Typography, Tooltip, Hidden} from '@material-ui/core';
import withWidth, {isWidthUp, WithWidth} from '@material-ui/core/withWidth';
import {useStyles} from './styles';
import {useCommonStyles} from 'App/themes';
import LinkWithParams from 'shared/components/Link';
import {useParams, useRouteMatch} from 'react-router-dom';
import HeaderLogo from '../Header/HeaderLogo';
import HeaderIcons from '../Header/HeaderIcons';
import {useFlags} from 'flagsmith/react';

interface Props extends WithWidth {
  modulesList: Array<{
    icon: JSX.Element;
    text: string;
    path: string;
    stuckToBottom?: boolean;
  }>;
  sideMenuBottomItems: Array<{
    icon: JSX.Element;
    text: string;
    path: string;
  }>;
  menuIsOpen: boolean;
  setMenuIsOpen: Function;
}

function SideMenu({modulesList, menuIsOpen, setMenuIsOpen, width, sideMenuBottomItems}: Props) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [open, setOpen] = React.useState(false);
  const {projectId} = useParams<{projectId: string}>();
  const route = useRouteMatch();
  const {data_sources} = useFlags(['data_sources']);

  // const menuPaper = isWidthUp('sm', width) ? {opened: open, closed: !open} : 'resMenu';

  const toggleDrawer = () => {
    setOpen(status => !status);
  };

  const MenuItem = useCallback(({icon, text, disabled, open}) => {
    return (
      <div className={classes.itemIcon}>
        <Tooltip
          title={text}
          placement="right"
          classes={{
            tooltip: clsx(classes.tooltip, {
              hidden: open,
            }),
          }}
        >
          <IconButton disabled={disabled} color="inherit">
            {icon}
          </IconButton>
        </Tooltip>
      </div>
    );
  }, []);

  return (
    <>
      <Box
        // variant={isWidthUp('sm', width) ? 'permanent' : 'persistent'}
        // open={menuIsOpen}
        className={clsx(classes.root)}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          className={clsx(commonClasses.fullHeight, classes.wrapper)}
        >
          <HeaderLogo menuIsOpen={menuIsOpen} searchIsOpen={false} showProjectsDropdown={false} />
          <Box px={2} mt={3} display={'flex'} flexDirection={'column'} height={'100%'}>
            {modulesList.map(({text, icon, path, stuckToBottom}, index) => {
              return projectId ? (
                <LinkWithParams
                  to={path}
                  key={index}
                  className={clsx(
                    classes.listItem,
                    {
                      selected: route.path.includes(path),
                    },
                    classes.link,
                    {stuckToBottom: stuckToBottom},
                    data_sources.enabled ? 'devicesEnabled' : 'devicesDisabled',
                  )}
                >
                  <MenuItem text={text} open={open} icon={icon} />
                  {/*<Typography variant="subtitle2" className={classes.itemTxt}>*/}
                  {/*  {text}*/}
                  {/*</Typography>*/}
                </LinkWithParams>
              ) : (
                <MenuItem text={text} disabled={!projectId} open={open} icon={icon} />
              );
            })}
          </Box>

          <Box mt={'auto'}>
            <HeaderIcons />
          </Box>
          <Hidden only="xs">
            <Box>
              {sideMenuBottomItems.map(({icon, text, path}, index) => (
                <LinkWithParams
                  key={index}
                  to={path}
                  className={clsx(classes.listItem, classes.link)}
                >
                  <div className={classes.itemIcon}>
                    <Tooltip
                      title={text}
                      placement="right"
                      classes={{
                        tooltip: clsx(classes.tooltip, {
                          hidden: open,
                        }),
                      }}
                    >
                      <IconButton color="inherit">{icon}</IconButton>
                    </Tooltip>
                  </div>
                  <Typography variant="subtitle2" className={classes.itemTxt}>
                    {text}
                  </Typography>
                </LinkWithParams>
              ))}
              {/*<div className={classes.listItem}>*/}
              {/*  <div className={classes.itemIcon}>*/}
              {/*    <Tooltip*/}
              {/*      title="expand"*/}
              {/*      placement="right"*/}
              {/*      classes={{*/}
              {/*        tooltip: clsx(classes.tooltip, {*/}
              {/*          hidden: open,*/}
              {/*        }),*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      <IconButton onClick={toggleDrawer} color="inherit">*/}
              {/*        {open ? <ChevronLeft fontSize="small" /> : <ChevronRight fontSize="small" />}*/}
              {/*      </IconButton>*/}
              {/*    </Tooltip>*/}
              {/*  </div>*/}
              {/*  <Typography className={classes.itemTxt}>Collapse</Typography>*/}
              {/*</div>*/}
            </Box>
          </Hidden>
        </Box>
      </Box>
      {/*<Hidden smUp>*/}
      {/*  <div*/}
      {/*    className={clsx(classes.menuOverlay, {*/}
      {/*      [classes.menuOverlayOpen]: Boolean(menuIsOpen),*/}
      {/*    })}*/}
      {/*    onClick={() => setMenuIsOpen(!menuIsOpen)}*/}
      {/*  />*/}
      {/*</Hidden>*/}
    </>
  );
}

export default withWidth()(SideMenu);
