import http, {Services} from 'adapters/rest';
import {Instance} from '../types';

export const getInstanceId = (name: string): Promise<Instance> => {
  const url = `${Services.IAM}/v1/instances/${name}`;
  return http
    .get<{
      result: {
        id: string;
        name: string;
        ownerUserAccountId: string;
        subName: string;
        role: string;
        updatedAt: number;
      };
    }>(url)
    .then(response => response.data.result);
    // .catch(err => err.response.data);
};
