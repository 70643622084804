import {useCallback} from 'react';
import {createGlobalState} from 'react-hooks-global-state';
import {UseInstanceHook, Instance} from './types';
import * as API from './api';
import {setInstanceId} from '../../adapters/rest';
import {getDomainName} from './utils';

type GlobalState = {
  instance: Instance | null;
};

const {useGlobalState} = createGlobalState<GlobalState>({
  instance: null,
});

function useInstanceData(): UseInstanceHook {
  const [instance, setInstance] = useGlobalState('instance');

  const getInstanceId = useCallback(() => {
    return API.getInstanceId(getDomainName()).then(instance => {
      setInstanceId(instance.id);
      setInstance(instance);
    });
  }, [setInstance]);

  return {
    instance,
    getInstanceId,
  };
}

export default useInstanceData;
