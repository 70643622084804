import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: 0,
      right: 0,
      left: 0,
      background: theme.palette.common.white,
    },
  },
  iconsBox: {
    zIndex: 10,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-around',
    },
  },
  menuOverlay: {
    position: 'fixed',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    background: 'rgba(0,0,0,0.7)',
    display: 'none',
  },
  menuOverlayOpen: {
    display: 'block',
  },
  icon: {
    padding: 4,
    '& svg': {
      width: 32,
      height: 32,
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: 'rgba(236, 247, 255, .2)',
    },
    '&.hide': {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  },
  popover: {
    padding: 16,
    width: 130,
    borderRadius: 10,
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
    '& .txt': {
      marginTop: 5,
      fontSize: 8,
      textAlign: 'center',
    },
  },
  applicationsLogo: {
    borderRadius: 10,
    padding: 9,
    width: 40,
    height: 40,
    background: '#f5f5f5',
    '&.selected': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '& > .svg': {
      width: '100%',
      height: '100%',
    },
  },
  profile: {
    position: 'relative',
    '&:hover': {
      backgroundColor: '#fff',
      color: theme.palette.primary.shades[10],
    },
    // marginBottom: 40,
    // '&::before': {
    //   content: '""',
    //   position: 'absolute',
    //   top: theme.spacing(2),
    //   bottom: theme.spacing(2),
    //   left: -1,
    //   width: 2,
    //   background: theme.palette.grey[300],
    //   [theme.breakpoints.down('xs')]: {
    //     display: 'none',
    //   },
    // },
  },
  profileIcon: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    overflow: 'hidden',
    '& img': {
      maxWidth: '100%',
    },
  },
  translateIcon: {
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
    background: theme.palette.primary.shades[12],
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      background: '#fff',
    },
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.dark,
    textTransform: 'capitalize',
    margin: theme.spacing(1, 0),
  },
  initialsAvatar: {
    width: '32px',
    height: '32px',
    cursor: 'pointer',
    // borderBottomLeftRadius: '50%',
    // borderBottomRightRadius: '50%',
    // borderTopLeftRadius: '50%',
    // borderTopRightRadius: '50%',
    backgroundColor: '#000',
    display: 'block',
    boxSizing: 'border-box',
    '& div': {
      lineHeight: '10px',
      fontSize: '14px',
      color: '#fff',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontStyle: 'inherit',
      fontWeight: 'inherit',
    },
  },
}));
