import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  img: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: "50%",
    overflow: "hidden",
  },
  link: {
    padding: theme.spacing(2, 4),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    display: "flex",
    textDecoration: "none",
    color: "inherit",
    cursor: "pointer",
  },
  linkIcon: {
    marginRight: theme.spacing(6),
  },
  initialsAvatar: {
    width: "35px",
    height: "35px",
    cursor: "pointer",
    // borderBottomLeftRadius: '50%',
    // borderBottomRightRadius: '50%',
    // borderTopLeftRadius: '50%',
    // borderTopRightRadius: '50%',
    backgroundColor: "#000",
    display: "block",
    boxSizing: "border-box",
    "& div": {
      lineHeight: "10px",
      color: "#fff",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontStyle: "inherit",
      fontWeight: "inherit",
    },
  },
  modalContainer: {
    boxShadow: "rgb(50 71 92 / 10%) 0px 2px 10px",
    borderRadius: 8,
    backgroundColor: "#ffff",
    padding: "45px",
    minWidth: "500px",
    position: "relative",
    minHeight: 450,
  },

  form: {
    display: "flex",
    flexDirection: "column",
  },
  textInputs: {
    marginBottom: 15,
  },
  selectInput: {
    "& .MuiOutlinedInput-input": {
      padding: "10px 18px ",
      textTransform: "lowercase",
    },
  },
  title: {
    "&.MuiTypography-root": {
      fontSize: 24,
      color: "rgba(50, 71, 92, 0.87)",
      fontWeight: "500",
      marginBottom: 6,
    },
  },
  subtitle: {
    "&.MuiTypography-root": {
      fontSize: 16,
      color: "rgba(50, 71, 92, 0.6)",
      fontWeight: "400",
    },
  },
  closeIcon: {
    position: "absolute",
    top: 20,
    right: 20,
    cursor: "pointer",
    color: "rgba(50, 71, 92, 0.6)",
    fontSize: 24,
  },
  messageContainer:{
    display:"flex", flexDirection:"column", justifyContent:'center', alignItems:"center", height:'360px'
 }
}));
