export enum ModulesPaths {
  ROOT = '/',
  THINGS = '/:projectId',
}

export enum FilterOperator {
  gt = 'gt',
  gte = 'gte',
  lt = 'lt',
  lte = 'lte',
  eq = 'eq',
  ne = 'ne',
  between = 'between',
  notBetween = 'notBetween',
  like = 'like',
  notLike = 'notLike',
  iLike = 'iLike',
  is = 'is',
  isNot = 'isNot',
  not = 'not',
  notILike = 'notILike',
  in = 'in',
  notIn = 'notIn',
  contains = 'contains',
}
export type FilterValue =
  | string
  | number
  | boolean
  | null
  | string[]
  | number[];
export type FilterState = {
  key: string;
  operator: FilterOperator;
  value: FilterValue;
};
export type FilterAction = {
  type: 'operator' | 'value';
  value: FilterValue | FilterOperator;
};
export type FilterReducer<S, A> = (prevState: S, action: A) => S;

export type ListResponse<T> = {
  result: T[];
  data?: T[];
  page: string | number;
  limit: number;
  count: number;
};

export interface DataListResponse<T> extends ListResponse<T> {
  data: T[];
}

export type TimeRange = [Date | null, Date | null];
// export interface TimeRange {
//   from?: string;
//   to?: string;
// }

export interface Pagination {
  page: number;
  limit: number;
  count: number;
}
export interface ListResponseData<T> extends Pagination {
  data: T[];
}

export interface TimeWindow {
  type: 'REAL_TIME' | 'HISTORY';
  rangeOptions?: {
    start: number;
    end: number;
  };
  startValues?: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
}

export interface Aggregation {
  func: string;
  interval: {
    unit: string;
    value: number;
  };
}

export enum DataSourceTypes {
  DEVICE = 'DEVICE',
  TAG = 'TAG',
  // ASSET = 'ASSET',
  // ASSET_TYPE = 'ASSET_TYPE',
}

export enum DataTypes {
  TELEMETRIES = 'TELEMETRIES',
  // ATTRIBUTES = 'ATTRIBUTES',
  ALARMS = 'ALARMS',
  // EVENTS = 'EVENTS',
}

export interface DataSource {
  id: string;
  name: string;
  dataType: DataSourceTypes;
  dataKeys?: string[];
  keyType: DataTypes;
  enableRealTime?: boolean;
  latestOnly?: boolean;
}

export enum SortOrders {
  ASC = 'asc',
  DESC = 'desc',
}

export interface Sort {
  [key: string]: SortOrders | undefined;
}
export type Filters = {
  [key: string]: FilterState;
};
