import React from 'react';
import { useStyles } from './styles';
import Icon from 'shared/assets/suite-logo.svg';
// import text from 'shared/types/Word_Logo_34.svg';

function GlobalLoader() {
  const classes = useStyles();

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        // background: 'rgb(24, 26, 27)',
      }}
    >
      <img src={Icon} alt={'selectInput'} style={{ height: '120px' }} />
      {/* <img src={text} alt={'selectInput'} style={{height: '60px'}} /> */}
      <div className={classes.spinner}>
        <div className={classes.bounce1} />
        <div className={classes.bounce2} />
        <div className={classes.bounce3} />
        <div className={classes.bounce4} />
      </div>
    </div>
  );
}

export default GlobalLoader;
