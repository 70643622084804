import {useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import * as Api from '../api';
import {useSubscriptionsStore} from '../index';
import {Subscriptions} from '../types';

const useSubscriptions = () => {
  const {projectId} = useParams<{projectId: string}>();
  const {setSubscriptions, setProjectId} = useSubscriptionsStore.getState();

  const getSubscriptions = useCallback(() => {
    Api.getSubscriptions(projectId).then((data: Subscriptions) => {
      setSubscriptions(data);
      setProjectId(projectId);
    });
    // .catch(() => {
    //   console.warn('error ya 7omar');
    // })
    // .finally(() => {
    //   const {subscriptions} = useSubscriptionsStore.getState();
    // });
  }, [projectId, setSubscriptions]);

  useEffect(() => {
    getSubscriptions();
  }, []);

  return {};
};

export default useSubscriptions;
