import React from "react";
import Modal from "@material-ui/core/Modal";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import Avatar from "@material-ui/core/Avatar";
import FirstTab from "./create-tabs/FirstTab";
import DoneIcon from "@material-ui/icons/Done";
import PaymentIcon from "@material-ui/icons/Payment";
import { ApplicationData } from "../types";
import SubmitTab from "./create-tabs/SubmitTab";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

export default function CreateModal({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (key: boolean) => void;
}) {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [isCreatingApp, setIsCreatingApp] = React.useState<boolean>(false);
    const [data, setData] = React.useState<ApplicationData>({
        name: "",
        description: "",
        plan: "starter-EUR-Monthly",
        planId: "starter",
    });

    const tabs = [
        {
            title: "Details",
            subtitle: "Enter Details",
            icon: (
                <DescriptionOutlinedIcon
                    className={value === 0 ? classes.activeTabIcon : classes.tabIcon}
                />
            ),
            component: <FirstTab data={data} setData={setData} setValue={setValue} />,
        },
        {
            title: "Billing",
            subtitle: "Payment details",
            icon: (
                <PaymentIcon
                    className={value === 1 ? classes.activeTabIcon : classes.tabIcon}
                />
            ),
            component: <FirstTab data={data} setData={setData} setValue={setValue} />,
        },
        {
            title: "Submit",
            subtitle: "Submit",
            icon: (
                <DoneIcon
                    className={value === 2 ? classes.activeTabIcon : classes.tabIcon}
                />
            ),
            component: (
                <SubmitTab
                    isCreatingApp={isCreatingApp}
                    setIsCreatingApp={setIsCreatingApp}
                    setOpen={setOpen}
                    setValue={setValue}
                    data={data}
                />
            ),
        },
    ];

    return (
        <Modal
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
            onClose={() => { }}
            open={open}
        >
            <Box className={classes.container}>
                <CloseIcon
                    className={clsx(classes.tabIcon, classes.closeIcon)}
                    onClick={() => (isCreatingApp ? {} : setOpen(false))}
                />
                <Box display="flex" flexDirection="column" mb={7} alignItems="center">
                    <Typography className={classes.title}>Create App</Typography>
                    <Typography className={classes.subtitle}>
                        Provide data with this form to create your app.
                    </Typography>
                </Box>
                <Box display="flex">
                    <Box display="flex" flexDirection="column">
                        {/* Tabs */}
                        {tabs.map((tab, i) => (
                            <Box key={tab.title} className={classes.tabContainer}>
                                <Avatar
                                    className={
                                        value === i ? classes.activeAvatar : classes.avatar
                                    }
                                >
                                    {tab.icon}
                                </Avatar>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="space-between"
                                >
                                    <Typography className={classes.tabTitle}>
                                        {tab.title}
                                    </Typography>
                                    <Typography className={classes.tabSubtitle}>
                                        {tab.subtitle}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>

                    {tabs[value].component}
                </Box>
            </Box>
        </Modal>
    );
}
