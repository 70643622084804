import React, {useState, lazy, useEffect, useMemo} from 'react';
import {Router, useParams} from 'react-router-dom';
import {renderRoutes, RouteConfig, RouteConfigComponentProps} from 'react-router-config';
import {StemPaths} from './types';
import WithLayout from './WithLayout';
import {
  Devices as DevicesIcon,
  Settings,
  SettingsInputAntenna,
  Domain,
  BarChartOutlined,
  ShareOutlined,
  TrackChangesOutlined,
  Build,
  Dashboard,
} from '@material-ui/icons';
import Layout from 'modules/Layout';
import useSubscriptions from 'shared/store/actions';
import useUserData from '../useUserData';
import {Permission} from 'modules/useUserData/type';
import {useFlags} from 'flagsmith/react';
import ErrorPage404 from 'shared/components/ErrorPages/404';
import {useTranslation} from 'react-i18next';

const Admin = lazy(() => import(/* webpackChunkName: "Admin" */ './Admin/'));
const Things = lazy(() => import(/* webpackChunkName: "Devices" */ './Admin/views/Things'));
const InventoryDevices = lazy(() => import(/* webpackChunkName: "Admin" */ './Devices'));
const InventoryAssets = lazy(() => import(/* webpackChunkName: "Admin" */ './Assets'));
const Redirect = lazy(() => import(/* webpackChunkName: "Redirect" */ './components/Redirect'));
const Monitoring = lazy(() => import(/* webpackChunkName: "Monitoring" */ './Monitoring'));
const Plans = lazy(() => import(/* webpackChunkName: "Monitoring" */ './Maintenance/views/plans'));
const Maintenance = lazy(() => import(/* webpackChunkName: "Monitoring" */ './Maintenance'));
const Alarms = lazy(() => import(/* webpackChunkName: "Monitoring" */ './Monitoring/views/Alarms'));
const Control = lazy(() => import(/* webpackChunkName: "Control" */ './Control'));
const Sites = lazy(() => import(/* webpackChunkName: "Control" */ './Sites'));
const SharedDashBoards = lazy(() => import(/* webpackChunkName: "Control" */ './SharedDashBoards'));
const Reporting = lazy(() => import(/* webpackChunkName: "Integrate" */ './Integrate/views'));
const Dashboards = lazy(() => import(/* webpackChunkName: "Dashboards" */ './Dashboards'));

const ViewItems = [
  {
    icon: <Domain fontSize="default" />,
    text: 'Places',
    path: StemPaths.SITES_SITE_TYPES,
  },
  {
    icon: <SettingsInputAntenna fontSize="default" />,
    text: 'Assets',
    path: StemPaths.ASSETS,
  },
  {
    icon: <DevicesIcon fontSize="default" />,
    text: 'Data Sources',
    path: StemPaths.DEVICES,
    flag: 'data_sources',
  },
  // {
  //   icon: <Widgets fontSize="default" />,
  //   text: 'Visualizations',
  //   path: StemPaths.VISUALIZATIONS,
  // },
  {
    icon: <BarChartOutlined fontSize="default" />,
    text: 'Monitoring',
    path: StemPaths.MONITORING,
    flag: 'monitoring',
  },

  {
    icon: <Dashboard fontSize="small" />,
    text: 'Dashboards',
    path: StemPaths.PROJECT_DASHBOARDS,
    flag: 'dashboards',
  },
  {
    icon: <TrackChangesOutlined fontSize="default" />,
    text: 'Control',
    path: StemPaths.CONTROL,
    flag: 'command_template',
  },
  {
    icon: <ShareOutlined fontSize="default" />,
    text: 'Integrate',
    path: StemPaths.REPORTING,
    flag: 'integrate',
  },
  {
    icon: <Build fontSize="default" />,
    text: 'Maintenance',
    path: StemPaths.MAINTENANCE,
    flag: 'maintenance',
  },
];

const IotPass = (props: RouteConfigComponentProps) => {
  const {history} = props;
  const [sideMenuItems, setSideMenuItems] = useState([]);
  useSubscriptions();
  const {projectId} = useParams<{projectId: string}>();
  const {getUserRoles} = useUserData();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const {t, i18n} = useTranslation();

  // const {permissions} = useAuthorizationStore.getState();
  getUserRoles().then((permissions: Permission[]) => {
    setIsAdmin(permissions.some(permission => permission.rsname === 'admin_management'));
  });
  const flags = useFlags([
    'maintenance',
    'command_template',
    'integrate',
    'dashboards',
    'monitoring',
    'app_title',
    'data_sources',
  ]);

  useEffect(() => {
    const {app_title} = flags;
    const data = JSON.parse((app_title.value as string) || '{}');
    document.title = data[i18n.language] || t('general.title', {defaultValue: 'Home'});
  }, [flags, i18n, t]);

  const modules = useMemo(() => {
    // @ts-ignore
    const enabledModules = ViewItems.filter(item => (item.flag ? flags[item.flag]?.enabled : true));
    return isAdmin
      ? [
          ...enabledModules,
          {
            icon: <Settings fontSize="default" />,
            text: 'Admin',
            path: StemPaths.ADMIN,
            stuckToBottom: true,
          },
        ]
      : enabledModules;
  }, [flags, isAdmin]);

  const wrapper =
    (
      Component: React.LazyExoticComponent<
        (props: RouteConfigComponentProps) => JSX.Element | null
      >,
    ) =>
    (props: RouteConfigComponentProps) => {
      return (
        <Layout sideMenuItems={modules} showProjectsDropdown={true}>
          <Component {...props} />
        </Layout>
      );
    };

  const routes: RouteConfig[] = [
    {
      path: [StemPaths.PROJECT],
      exact: true,
      redirectTo: StemPaths.SITES_SITE_TYPES,
      component: wrapper(Redirect),
    },
    {
      path: [StemPaths.PROJECT, StemPaths.ADMIN],
      exact: true,
      redirectTo: StemPaths.SITE_TYPES,
      component: wrapper(Redirect),
    },
    {
      path: StemPaths.PROJECT,
      component: WithLayout,
      sideMenu: true,
      routes: [
        // {
        //   path: [StemPaths.ALARMSTab],
        //   exact: true,
        //   redirectTo: StemPaths.ALARMS_TYPES,
        //   component: wrapper(Redirect),
        // },
        // {
        //   path: StemPaths.ALARMSTab,
        //   // exact: true,
        //   name: 'Alarms',
        //   component: wrapper(Alarms),
        // },
        // {
        //   path: StemPaths.VISUALIZATIONS,
        //   // exact: true,
        //   name: 'Overview',
        //   component: wrapper(Visualization),
        // },
        {
          path: StemPaths.SITES_SITE_TYPES,
          // exact: true,
          redirectTo: StemPaths.SITES_SITE_TYPES,
          component: wrapper(Sites),
        },
        {
          path: StemPaths.CONTROL,
          flag: 'command_template',
          exact: true,
          redirectTo: StemPaths.SCHEDULER,
          component: wrapper(Redirect),
        },
        {
          path: StemPaths.CONTROL,
          flag: 'command_template',
          // exact: true,
          component: wrapper(Control),
          // routes: [
          //   {
          //     path: StemPaths.SCHEDULER,
          //     // exact: true,
          //     name: 'scheduler',
          //     component: Scheduler,
          //   },
          // ],
        },
        {
          path: StemPaths.REPORTING,
          // exact: true,
          component: wrapper(Reporting),
        },
        {
          path: StemPaths.ADMIN,
          // exact: true,
          name: 'Admin',
          component: wrapper(Admin),
          routes: [
            {
              path: StemPaths.THINGS,
              // exact: true,
              name: 'ThingsProfiles',
              component: Things,
            },
          ],
        },
        {
          path: StemPaths.MONITORING,
          exact: true,
          redirectTo: StemPaths.OVERVIEW,
          component: wrapper(Redirect),
        },
        {
          path: StemPaths.MONITORING,
          // exact: true,
          name: 'Monitoring',
          component: wrapper(Monitoring),
          routes: [
            {
              path: StemPaths.OVERVIEW,
              // exact: true,
              name: 'alarms',
              component: Alarms,
            },
          ],
        },
        {
          path: StemPaths.PROJECT_DASHBOARDS,
          component: wrapper(Dashboards),
        },
        {
          path: StemPaths.MAINTENANCE,
          flag: 'maintenance',
          exact: true,
          redirectTo: StemPaths.MAINTENANCE_WORKORDERS,
          component: wrapper(Redirect),
        },
        {
          path: StemPaths.MAINTENANCE,
          flag: 'maintenance',
          // exact: true,
          name: 'Maintenance',
          component: wrapper(Maintenance),
          routes: [
            // {
            //   path: StemPaths.MAINTENANCE_PLANS,
            //   // exact: true,
            //   name: 'plans',
            //   component: Plans,
            // },
          ],
        },
        {
          path: [
            StemPaths.DEVICES_THING_PROFILE_DASHBOARD,
            StemPaths.THING_PROFILE_DASHBOARD,
            StemPaths.PROJECT_DASHBOARD_VIEWER,
          ],
          // exact: true,
          component: SharedDashBoards,
        },
        // component: () => (
        //   <QueryParamProvider ReactRouterRoute={Route}>
        //     {<Grid isViewOnly={true} />}
        //   </QueryParamProvider>
        // ),
        // component: () => (
        //   <QueryParamProvider ReactRouterRoute={Route}>
        //     <Grid isViewOnly={true} isThingProfile={false} />
        //   </QueryParamProvider>
        // ),
        {
          path: [StemPaths.DEVICES],
          // exact: true,
          flag: 'data_sources',
          redirectTo: StemPaths.DEVICES,
          component: wrapper(InventoryDevices),
        },
        {
          path: [StemPaths.ASSETS],
          // exact: true,
          redirectTo: StemPaths.ASSETS,
          component: wrapper(InventoryAssets),
        },
        {
          path: '*',
          component: ErrorPage404,
        },
      ],
    },
  ]
    // @ts-ignore
    .filter(route => ('flag' in route ? flags[route.flag]?.enabled : true))
    .map(route => {
      if (route.routes && route.routes.length > 0) {
        route.routes = route.routes.filter(subRoute =>
          // @ts-ignore
          'flag' in subRoute ? flags[subRoute.flag]?.enabled : true,
        );
      }
      return route;
    });

  return (
    <Router history={history}>{renderRoutes(routes, {setSideMenuItems, sideMenuItems})}</Router>
  );
};

export default IotPass;
