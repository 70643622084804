import API, {Services} from 'adapters/rest';
import {injectParamsIntoUrl} from 'shared/utils';
import {Subscription} from '../types';

export const getSubscription = (projectId: string): Promise<Subscription> => {
  const url = injectParamsIntoUrl(
    `/${Services.SUBSCRIPTION}/v1/projects/:projectId/subscriptions`,
    {
      projectId,
    },
  );
  return API.get(url).then(response => response.data.result);
};
