import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 10,
    position: 'relative',
    transition: 'all 0.2s',
    '&.resSearchIsOpen': {
      visibility: 'hidden',
      opacity: 0,
      maxWidth: 0,
      // display:'none'
    },
  },
  homeButton: {
    // marginRight: theme.spacing(4),
    // cursor: 'default',
    // '&:hover': {
    //   background: 'transparent',
    // },
  },
  logo: {
    // width: 185,
    width: 30,
    marginTop: 5,
  },
  logoLink: {
    position: 'relative',
    // '&:before': {
    //   content: "''",
    //   position: 'absolute',
    //   top: 10,
    //   bottom: 10,
    //   width: 1,
    //   background: '#fff',
    //   right: -10,
    // },
  },
  logoThings: {
    width: 140,
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  title: {
    // color: '#ffffff',
    marginRight: theme.spacing(9),
    lineHeight: '.5',
  },
  menuButton: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
}));
