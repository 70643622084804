import React, {useCallback, useEffect, useState} from 'react';
import {Box, TextField, Typography} from '@mui/material';
import debounce from 'lodash.debounce';
import API from 'adapters/rest';
import {Body, InfiniteScroll} from '@cervello/ui-collection';
import {CircularLoader} from 'shared/components/Loaders/CircularLoader';
import emptyState from 'shared/assets/landingpage-empty-state.svg';
import Application, {App} from '../../App';
import {useStyles} from '../../styles';

///v1/sharedApplications

let pagination = {
  nextPage: 1,
  count: 0,
  limit: 10,
};

export default function SharedApplications() {
  const [Applications, setApplications] = React.useState<App[]>([]);
  const [searchKey, setSearchKey] = React.useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const classes = useStyles();

  const getData = useCallback(
    (isPagination = false, searchKey = '') => {
      const url = `/iam/v1/sharedApplications?${`limit=10&offset=${isPagination ? pagination.nextPage * 10 : 0}${`&&name=${searchKey}`}`}`;
      API.get(url)
        .then(response => {
          const {data, page, limit, count} = response.data.result;
          if (isPagination) {
            pagination = {nextPage: page + 1, limit, count};
            setApplications(prevState => [...prevState, ...data]);
          } else {
            //   setIsLoading(false);
            pagination = {nextPage: 1, limit, count};
            setApplications(data);
            setIsLoading(false);
          }
        })
        .finally(() => setIsLoading(false));
    },
    [searchKey],
  );

  const handleSearch = useCallback(
    debounce((text: string) => {
        setApplications([]);
        setIsLoading(true);
        setSearchKey(text);
        getData(false, text);
      }, 400,
      {
        leading: false,
        trailing: true,
      }),
    [],
  );

  useEffect(() => {
    getData();
  }, []);


  return (
    <React.Fragment>
      <Box className={classes.rightColumn}>
        <Box display='flex' justifyContent='space-between' alignItems='end'>
          <Typography className={classes.title} style={{color: '#282828', fontSize: '24px'}}> Shared with me
            Applications </Typography>
          <Box display='flex'>
            <TextField
              placeholder='Search'
              onChange={(e) => {
                //setApplications([])
                handleSearch(e.target.value);
              }}
              variant='outlined'
              InputProps={{
                className: classes.searchInput,
                style: {borderRadius: '6px'},
              }} />
          </Box>
        </Box>

        {isLoading ? (
            <CircularLoader />
          ) :
          Applications.length > 0 ? (
            <InfiniteScroll
              dataLength={Applications.length}
              next={() => getData(true, searchKey)}
              hasMore={!isLoading && Applications.length < pagination.count}
              loader={<CircularLoader />}
              cardDimension={{
                width: 270,
                height: 290,
              }}
              style={{overflow: 'auto'}}
              height='calc(100vh - 80px)'
              className={classes.scrollable}
            >
              <div style={{margin: '30px 0px 0px 15px', display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                {Applications.map((app) => (
                  <Application
                    {...app}
                    key={app.id}
                  />))}
              </div>
            </InfiniteScroll>

          ) : searchKey ? (
            <Box
              mt={5}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              height='calc(100vh - 140px)'
              width='100%'
            >
              <img src={emptyState} style={{width: '225px', height: '230px'}} alt={'empty state'} />

              <Typography className={classes.mt3}>No Applications with this name</Typography>
              <Body variant={'subtitle2'} className={classes.mt1}>
                {'Try another name?'}
              </Body>

            </Box>
          ) : (
            <Box
              mt={5}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              height='calc(100vh - 140px)'
              width='100%'
            >
              <img src={emptyState} style={{width: '225px', height: '230px'}} alt={'empty state'} />

              <Typography className={classes.mt3}>There is no available shared applications</Typography>
              <Body variant={'subtitle2'} className={classes.mt1}>
                {'Contact your admin?'}
              </Body>

            </Box>
          )}

      </Box>
    </React.Fragment>
  );
}


