import React, {useEffect} from 'react';
import {Box, Paper, Typography} from '@material-ui/core';
import HeaderLogo from './HeaderLogo/';
import HeaderIcons from './HeaderIcons';
import {Link} from '@cervello/ui-collection';
import {useStyles} from './styles';
import {useHeader} from '../actions';

interface Props {
  setMenuIsOpen?: Function;
  menuIsOpen?: boolean;
  showProjectsDropdown: boolean;
}

export default function Header({
  setMenuIsOpen,
  menuIsOpen = false,
  showProjectsDropdown = false,
}: Props) {
  const classes = useStyles();
  const [searchIsOpen, setSearchIsOpen] = React.useState<boolean>(false);
  const {getSubscription, organizationName, status, trialPeriod, planId} = useHeader();
  const {DEPLYMENT_TYPE} = (window as any).env;

  useEffect(() => {
    DEPLYMENT_TYPE !== 'on-premise' && getSubscription();
  }, [DEPLYMENT_TYPE]);

  return (
    <Paper className={classes.root} square={true}>
      <Box
        display="flex"
        className={classes.header}
        justifyContent={searchIsOpen ? 'flex-end' : 'space-between'}
        alignItems="center"
        height="100%"
      >
        <HeaderLogo
          // setMenuIsOpen={setMenuIsOpen}
          menuIsOpen={menuIsOpen}
          searchIsOpen={searchIsOpen}
          showProjectsDropdown={showProjectsDropdown}
        />
        {/*<SearchInput searchIsOpen={searchIsOpen} setSearchIsOpen={setSearchIsOpen} />*/}

        {DEPLYMENT_TYPE !== 'on-premise' &&
          (status === 'in_trial' ? (
            <Box display={'flex'} alignItems={'center'} className={classes.freeTrialBox}>
              <Typography className={'txt'}>
                {`${trialPeriod} free days are left of free trial of ${organizationName}`}
              </Typography>
              <Link
                to={`https://${window.location.host}/profile/billing-info/${planId}/plans`}
                newPage
                className={'link'}
              >
                {'Subscribe now'}
              </Link>
            </Box>
          ) : (
            <Box display={'flex'} alignItems={'center'} className={classes.freeTrialBox}>
              <Typography className={'txt'}>{`${organizationName}`}</Typography>
            </Box>
          ))}

        <HeaderIcons />
      </Box>
    </Paper>
  );
}
