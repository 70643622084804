// import {actions} from './name.ts';
import socket, {Socket} from 'socket.io-client';
import {authorizationToken} from '../rest';
// import {APIs} from '../../adapters/httpAdapter/http';

const {
  REACT_APP_SOCKET_URL,
} = (window as any).env;

if (!REACT_APP_SOCKET_URL) {
  throw new Error('Socket URL is not defined!');
}

let SocketClient: Socket;
const entitiesListeners: {
  [eventName: string]: {
    cb: Function;
    eventName: string;
  };
} = {};

const registerSocketEvents = (SocketClient: Socket) => {
  SocketClient.on('connect', () => {
    console.info('* Socket connected.');
  });

  SocketClient.on('disconnect', (reason: string) => {
    console.info('* Socket disconnected.', reason);
  });

  SocketClient.on('reconnecting', (attempt: string) => {
    console.info('* Socket reconnecting.', attempt);
  });

  SocketClient.on('connect_error', error => {
    console.error('* Socket connect error.', error);
  });

  SocketClient.on('connect_timeout', (timeout: string) => {
    console.error('* Socket timeout.', timeout);
  });

  SocketClient.on('error', (err: any) => {});
};

export const connectSocket = (projectId: string) => {
  if (!SocketClient) {
    console.info('Initializing socket');
    const token = authorizationToken.get();
    SocketClient = socket(`${REACT_APP_SOCKET_URL}/?token=${token}`, {
      withCredentials: true,
      extraHeaders: {
        'Access-Control-Allow-Origin': window.location.origin,
      },
    });
    // sendSocketToken(token);
    SocketClient.emit('join', projectId);
    registerSocketEvents(SocketClient);
  }
};

export const disconnectSocket = () => {
  SocketClient.disconnect();
};

export const emitMessage = (message: string, payload: any) => {
  console.info(`* Sending message ${message}.`, payload);
  SocketClient.emit(message, payload);
};

// export const removeAllEventListeners = () => {
//   console.info('* Removing events.');
//   SocketClient.removeEventListener();
//   // dispatch(actions.setActiveEvents({}));
//   entitiesListeners = {};
// };

export const listenOnEvent = <T = any>(
  eventName: string,
  callback: (data: T) => void,
  identifier?: string | number,
) => {
  if (!SocketClient) {
    throw new Error('Socket is not initialized!');
  }

  const customCallBack = (data: T) => {
    callback(data);
  };
  const eventId = identifier || eventName;

  entitiesListeners[eventId] = {cb: customCallBack, eventName};
  // SocketClient.emit('join', params.organizationId);
  SocketClient.on(eventName, (data: any) => {
    customCallBack(data);
  });
};

export const removeEventListener = (identifier: string | number) => {
  const listener = entitiesListeners[identifier];
  if (listener) {
    SocketClient.removeListener(listener.eventName);
  }
};

// const handleClientConnect = () => (dispatch: Function): void => {
//   dispatch(actions.setStatus(true));
// };

// const handleClientDisconnect = () => (dispatch: Function): void => {
//   dispatch(actions.setStatus(false));
// };

// export const reconnectSocket = () => (dispatch: Function): void => {};

const sendSocketToken = (token: string) => {
  SocketClient.on('connect', () => {
    SocketClient.emit('authentication', {authorization: token});
  });
};
