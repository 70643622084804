import {Project} from '../types/Projects';
import API, {Services} from 'adapters/rest';
import {ListResponse} from 'shared/types';

export const getProject = (instanceId: string, projectId: string): Promise<Project> => {
  const url = `/${Services.IAM}/v1/instances/${instanceId}/projects/${projectId}`;

  return API.get<{data: Project}>(url).then(response => response.data.data);
};

export const getProjects = (instanceId: string): Promise<ListResponse<Project>> => {
  const url = `/${Services.IAM}/v1/instances/${instanceId}/projects`;
  return API.get(url).then(response => response.data.result);
};

// export const createProject = (
//   accountId: string,
//   projectName: string,
//   teamId: string,
// ): Promise<string> => {
//   const url = injectParamsIntoUrl(
//     `/${Services.THINGS}/v1/accounts/:accountId/teams/:teamId/projects`,
//     {
//       teamId,
//       accountId,
//     },
//   );
//
//   return API.post<{result: string}>(url, {title: projectName}).then(
//     response => response.data.result,
//   );
// };

// export const createFullProject = (
//   accountId: string,
//   project: {
//     projectName: string;
//     teamName: string;
//     teamMembers: string[];
//   },
// ): Promise<string> => {
//   const url = injectParamsIntoUrl(`/${Services.THINGS}/v1/accounts/:accountId/projects`, {
//     accountId,
//   });
//
//   return API.post<{result: string}>(url, project).then(response => response.data.result);
// };
