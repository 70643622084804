const {
  REACT_APP_API_URL,
  REACT_APP_VISUALIZATION_EDITOR_URL,
  REACT_APP_SOCKET_URL,
} = (window as any).env;

export const apiUrl = REACT_APP_API_URL;
export const socketUrl = REACT_APP_SOCKET_URL;
export const visualizationEditorUrl =
  REACT_APP_VISUALIZATION_EDITOR_URL || "http://localhost:3001";
