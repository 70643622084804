import * as api from '../api';
import {useParams} from 'react-router-dom';
import {useState} from 'react';
import {useSubscriptionsStore} from 'shared/store';

export const useHeader = () => {
  const [status, setStatus] = useState<'in_trial' | 'active' | undefined>(undefined);
  const [trialPeriod, setTrialPeriod] = useState<number>(0);
  const [planId, setPlanId] = useState<string>('');
  const [organizationName, setOrganizationName] = useState<string>('');
  const {setSubscriptionId, setSubscriptionPlanId} = useSubscriptionsStore.getState();

  const {projectId} = useParams<{
    projectId: string;
  }>();

  const getSubscription = () => {
    api.getSubscription(projectId).then(data => {
      setStatus(data.subscriptionDetails.status);
      setOrganizationName(data.subscriptionDetails.subscriptionMetaData.organizationName);
      setTrialPeriod(
        Math.floor(
          (data.subscriptionDetails.trialEnd - data.subscriptionDetails.trialStart) /
            (60 * 60 * 24),
        ),
      );
      setPlanId(data.planId);
      setSubscriptionId(data.id);
      setSubscriptionPlanId(data.planId);
    });
  };

  return {
    getSubscription,
    organizationName,
    status,
    trialPeriod,
    planId,
  };
};
