import React from 'react';
import {Box, CircularProgress, CircularProgressProps} from '@material-ui/core';

export function CircularLoader(props: CircularProgressProps) {
  const {size = 20} = props;

  return (
    <Box mx="auto" mt={3} textAlign="center">
      <CircularProgress color="secondary" size={size} />
    </Box>
  );
}
