import React from 'react';
import {Box, Link} from '@material-ui/core';
import clsx from 'clsx';
import {
  IMessage,
  NotificationBell,
  NovuProvider,
  PopoverNotificationCenter,
} from '@novu/notification-center';
import {
  Business,
  Devices,
  Event,
  NotificationsActive,
  SettingsInputAntenna,
} from '@material-ui/icons';
import {useParams} from 'react-router-dom';
import useUserData from '../../useUserData';
import {useStyles} from './styles';
import {StemPaths} from '../../IotPaaS/types';
import LinkWithParams from '../../../shared/components/Link';

function timeSince(date: Date) {
  //@ts-ignore
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} years`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} months`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)} days`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)} hours`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)} minutes`;
  }
  return `${Math.floor(seconds)} seconds`;
}

const getSeverity = {
  1: {key: 'minor', color: '#ebe327'},
  2: {key: 'major', color: '#FFC187'},
  3: {key: 'critical', color: '#FCA5A5'},
};

const getStatus = {
  1: {key: 'active', color: 'rgb(253, 140, 140)'},
  2: {key: 'acknowledged', color: 'rgb(156, 253, 140)'},
  3: {key: 'cleared', color: 'rgb(156, 253, 140)'},
};

const getTypeIcon = {
  DEVICE: {key: 'Device', icon: <Devices fontSize={'small'} className={'icon'} />},
  ASSET: {key: 'Asset', icon: <SettingsInputAntenna fontSize={'small'} className={'icon'} />},
  SITE: {key: 'Place', icon: <Business fontSize={'small'} className={'icon'} />},
};

export default function Notification() {
  const classes = useStyles();
  const {projectId} = useParams<{projectId: string}>();
  const {user} = useUserData();
  const {REACT_APP_NOVU_URL, REACT_APP_NOVU_SOCKET_URL, REACT_APP_NOVU_IDENTIFIER} = (window as any)
    .env;

  function onNotificationClick(message: IMessage) {
    if (message?.cta?.data?.url) {
      window.location.href = message.cta.data.url;
    }
  }
  return (
    <Box className={classes.root}>
      <NovuProvider
        subscriberId={`${projectId}_${user?.sub}`}
        applicationIdentifier={REACT_APP_NOVU_IDENTIFIER}
        backendUrl={REACT_APP_NOVU_URL}
        socketUrl={REACT_APP_NOVU_SOCKET_URL}
      >
        <PopoverNotificationCenter
          colorScheme={'light'}
          position={'bottom-end'}
          offset={20}
          onNotificationClick={onNotificationClick}
          listItem={(message, handleActionButtonClick, handleNotificationClick) => (
            <Box
              className={clsx(classes.item, {read: message.read}, {notSeen: !message.seen})}
              onClick={handleNotificationClick}
            >
              <LinkWithParams
                className={classes.link}
                to={
                  message.templateIdentifier === 'alarm'
                    ? StemPaths.ALARMS.replace(':projectId', projectId)
                    : message.templateIdentifier === 'event'
                    ? StemPaths.EVENTS.replace(':projectId', projectId)
                    : ''
                }
              >
                {message.templateIdentifier === 'alarm' ? (
                  <Box display={'flex'} alignItems={'center'}>
                    <NotificationsActive />
                    <Box ml={1} className={'text'}>
                      {/*@ts-ignore*/}
                      {`Alarm: ${message.payload?.payload?.title}`}
                    </Box>
                  </Box>
                ) : message.templateIdentifier === 'event' ? (
                  <Box display={'flex'} alignItems={'center'}>
                    <Event />
                    <Box ml={1} className={'text'}>
                      {/*@ts-ignore*/}
                      {`Event: ${message.payload?.payload?.title}`}
                    </Box>
                  </Box>
                ) : (
                  <>
                    <div dangerouslySetInnerHTML={{__html: message.content as string}} />
                    {message.payload?.name && (
                      <Box ml={1} className={'text'}>
                        {message.payload?.name}
                      </Box>
                    )}
                  </>
                )}
                {message.templateIdentifier === 'alarm' && (
                  <>
                    <Box mt={1} display={'flex'} alignItems={'center'}>
                      {/*{getTypeIcon[message.payload?.payload?.originatorType].icon}*/}
                      {/*@ts-ignore*/}
                      <div> {getTypeIcon[message.payload?.payload?.originatorType].key}</div>
                      <div className={'text'}>{message.payload.originatorName}</div>
                    </Box>
                    <Box mt={1.5} display={'flex'} alignItems={'center'}>
                      <Box display={'flex'} alignItems={'center'}>
                        <div
                          className={'alarmAction'} /*@ts-ignore*/
                          style={{background: getStatus[message.payload?.payload?.status].color}}
                        />
                        {/*@ts-ignore*/}
                        {getStatus[message.payload?.payload?.status].key}
                      </Box>
                      {/*@ts-ignore*/}
                      <Box ml={3} display={'flex'} alignItems={'center'}>
                        <div
                          className={'alarmAction'}
                          style={{
                            /*@ts-ignore*/
                            background: getSeverity[message.payload?.payload?.severity].color,
                          }}
                        />
                        {/*@ts-ignore*/}
                        {getSeverity[message.payload?.payload?.severity].key}
                      </Box>
                    </Box>
                  </>
                )}
                {message.templateIdentifier === 'event' && (
                  <>
                    <Box mt={1} display={'flex'} alignItems={'center'}>
                      {/*@ts-ignore*/}
                      <div> {getTypeIcon[message.payload?.payload?.originatorType].key}</div>
                      <div className={'text'}>{message.payload?.originatorName}</div>
                    </Box>
                  </>
                )}
              </LinkWithParams>
              <div className={'date'}>{`${timeSince(new Date(message.createdAt))} ago`}</div>
            </Box>
          )}
        >
          {({unseenCount}) => <NotificationBell unseenCount={unseenCount} />}
        </PopoverNotificationCenter>
      </NovuProvider>
    </Box>
  );
}
