import React, {useState, useEffect} from 'react';
import Loader from 'shared/components/Loaders/GlobalLoader';
import {matchRoutes, renderRoutes, RouteConfigComponentProps} from 'react-router-config';
import {withRouter} from 'react-router-dom';
import {Feedback} from '../modules/Feedback';
import useUserData from '../modules/useUserData';

function Root(props: RouteConfigComponentProps) {
  const {user, authenticate, getUserData, authenticated} = useUserData();
  const [isReady, setIsReady] = useState(false);
  const {route: {routes = []} = {}, history} = props;
  const branch = matchRoutes<{projectId?: string}>(routes, window.location.pathname);
  const [{route}] = branch;

  useEffect(() => {
    if (authenticated()) {
      if (!user) {
        getUserData().then(r => {});
      }
      setIsReady(true);
      return;
    } else {
      authenticate().then(r => {});
    }
  }, [user, authenticate, authenticated, getUserData]);

  if (!isReady) {
    return <Loader />;
  }

  if (!route.isPublic && !user) {
    return null;
    // return redirect(ModulesPaths.AUTH);
  } else {
    const routeRenderer = renderRoutes(routes, {history});
    return <Feedback>{routeRenderer}</Feedback>;
  }
}

export default withRouter(Root);
