import {History, LocationDescriptorObject} from 'history';
import {RouteConfig} from 'react-router-config';
import {useLocation} from 'react-router-dom';
import {format} from 'date-fns';
import qs from 'qs';
import {FilterOperator, FilterReducer, FilterState, FilterValue} from '../types';
import {AlarmSeverities, AlarmStatuses} from '../../modules/IotPaaS/types/Alarms';
const {REACT_APP_DEVICES_RESOURCE_ID} = (window as any).env;

interface ChildHistoryOptions {
  history: History;
  basename?: string;
}

// Replacement methods for the history.
// Prefixes navigation and HREF creation params with the provided base path
// before calling the original history methods.
const createChildHistoryMethods = ({
  history,
  basename = '',
}: ChildHistoryOptions): Pick<History, 'push' | 'replace' | 'createHref'> => ({
  push(path: string | LocationDescriptorObject, state?: never) {
    if (typeof path === 'string') {
      history.push(basename + path, state);
    } else {
      return history.push({
        ...path,
        pathname: basename + path.pathname,
      });
    }
  },
  replace(path: string | LocationDescriptorObject, state?: never) {
    if (typeof path === 'string') {
      history.replace(basename + path, state);
    } else {
      return history.replace({
        ...path,
        pathname: basename + path.pathname,
      });
    }
  },
  createHref(location: LocationDescriptorObject) {
    const prefixedLocation = {
      ...location,
      pathname: basename + location.pathname,
    };

    return history.createHref(prefixedLocation);
  },
});

// History proxy implementation for the child applications.
// Proxies calls to history to the replacements methods provided above.
export const createChildHistory = (options: ChildHistoryOptions) => {
  const {history} = options;
  const childHistoryMethods = createChildHistoryMethods(options);

  return new Proxy(history, {
    get(target, key) {
      const originalMethod = target[key];
      const proxyMethod = childHistoryMethods[key];
      if (proxyMethod) {
        return proxyMethod;
      }

      return originalMethod;
    },
  });
};

export const prefixRoutes = (prefix: string, routes: RouteConfig[]): RouteConfig[] => {
  return routes.map(route => {
    // const routePath = Array.isArray(route.path) ? route.path.map(path => prefix + path) : prefix + route.path;
    return {
      ...route,
      path: Array.isArray(route.path) ? route.path.map(path => prefix + path) : prefix + route.path,
      ...(route.routes && {routes: prefixRoutes(prefix, route.routes)}),
    };
  });
};

export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export const injectParamsIntoUrl = <Params extends {[K: string]: string}>(
  url: string,
  params: Params,
): string => {
  Object.keys(params).forEach(key => {
    const paramValue = params[key];
    if (paramValue) {
      url = url.replace(`:${key}`, encodeURIComponent(paramValue));
    }
  });

  return url;
};

export const formatDate = (
  date: string | number,
  style: 'dateOnly' | 'dateTimeWithoutSec' | 'dateTime' = 'dateTime',
): string => {
  const newDate = new Date(date);
  let dateStyle;
  switch (style) {
    case 'dateOnly':
      dateStyle = 'dd/MM/yyyy';
      break;
    case 'dateTimeWithoutSec':
      dateStyle = 'dd/MM/yyyy HH:mm';
      break;
    default:
      dateStyle = 'dd/MM/yyyy HH:mm:ss';
      break;
  }
  return format(newDate, dateStyle);
};

type DurationFormats = 'minutes' | 'seconds';

export const msToTimeHours = (duration: number, formats: DurationFormats = 'seconds'): string => {
  // const milliseconds = (duration % 1000) / 100;
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  const dHours = hours < 10 ? `0${hours}` : hours;
  const dMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const dSeconds = seconds < 10 ? `0${seconds}` : seconds;

  switch (formats) {
    case 'minutes':
      return `${dHours}:${dMinutes}`;
    default:
      return `${dHours}:${dMinutes}:${dSeconds}`;
  }
};

export const filterReducer: FilterReducer<FilterState, FilterValue> = (prevState, value) => {
  let shouldReturnNull = false;
  const isDeselect = prevState.value === value;
  if (isDeselect) {
    shouldReturnNull = true;
  }
  if ([FilterOperator.iLike, FilterOperator.like].includes(prevState.operator)) {
    return {...prevState, value: isDeselect || !value ? null : `%${value}%`};
  }
  if (Array.isArray(value) && !value.length) {
    shouldReturnNull = true;
  }
  return {...prevState, value: shouldReturnNull ? null : value};
};

/**
 *
 * The next comment is an example for filter state
 * using the above filterReducer and FilterState
 *
 */

// const [startTimeFilter, updateStartTimeFilter] = useReducer(filterReducer, {
//   key: 'startTime',
//   operator: 'gte',
//   value: null,
// });

export const createSearchQuery = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>,
  options = {encode: false, addQueryPrefix: true},
): string => qs.stringify(data, options);

export const STATUS_MAP = {
  [AlarmStatuses.UNVERIFIED]: 'Not Verified',
  [AlarmStatuses.ACTIVE]: 'Active',
  [AlarmStatuses.ACKNOWLEDGED]: 'Acknowledged',
  [AlarmStatuses.CLEARED]: 'Cleared',
  [AlarmStatuses.FALSE]: 'Ignored',
};

export const SEVERITY_MAP = {
  [AlarmSeverities.MINOR]: 'Minor',
  [AlarmSeverities.MAJOR]: 'Major',
  [AlarmSeverities.CRITICAL]: 'Critical',
};

export const DEVICE_RESOURCE_ID =
  REACT_APP_DEVICES_RESOURCE_ID || '13e7493c-3fd4-4010-a8dc-f50e2774549e';
