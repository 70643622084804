import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // boxShadow: '0px 0px 8px rgba(156, 155, 155, 0.1)',
    // boxShadow: '0px 1px 2px rgb(0 0 0 / 25%)',
    boxShadow: 'none',
    height: theme.custom.headerHeight,
    position: 'fixed',
    width: '100%',
    zIndex: 2,
  },
  header: {
    backgroundColor: '#1034a6',
    borderRadius: '0 0 10px 10px',
    padding: theme.spacing(0, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3),
    },
  },
  freeTrialBox: {
    background: '#ffffff1a',
    borderRadius: 25,
    padding: theme.spacing(1, 2.5),
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    color: '#fff',

    '& .txt': {
      fontSize: 14,
    },
    '& .link': {
      textTransform: 'uppercase',
      color: '#fff',
      marginLeft: theme.spacing(1),
      display: 'block',
      fontSize: 14,
      position: 'relative',
      '&:after': {
        content: "''",
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: 1,
        background: '#fff',
      },
    },
  },
}));
