import Colors from '@cervello/ui-collection/dist/utils/colors.json';

export enum AlarmStatuses {
  UNVERIFIED = 0,
  ACTIVE,
  ACKNOWLEDGED,
  CLEARED,
  FALSE,
}

export enum AlarmSeverities {
  MINOR = 1,
  MAJOR,
  CRITICAL,
}

export interface AlarmStatics {
  count: string;
  start_time: string;
  severity: number;
  status: string;
  type: string;
}

export interface Alarm {
  id: string;
  title: string;
  details: string;
  projectId: string;
  resourceId: string;
  startTime: string;
  resourceName?: string;
  type: string;
  status: number;
  severity: AlarmSeverities;
  // ackTime: string | null;
  // ackUserId: string | null;
  // ackUserEmail: string | null;
  // ackReason: string | null;
  // endTime: string | null;
  // clearTime: string | null;
  // clearUserId: string | null;
  // clearUserEmail: string | null;
}

export interface AlarmSocket {
  id: string;
  originatorName: string;
  originatorType: string;
  projectId: string;
  propagate: boolean;
  resourceId: string;
  severity: 1 | 2 | 3;
  status: 1 | 2 | 3;
  title: string;
  type: string;
  createdAt: number;
  updatedAt: number;
  details: string;
}

export interface AssetAlarm {
  id: string;
  title: string;
  type: string;
  originatorType: 'DEVICE' | 'ASSET';
  originatorId: string;
  originatorName: string;
  startTime: string;
  createdAt: string;
  updatedAt: string;
  severity: 1 | 2 | 3;
  status: 0 | 1 | 2 | 3 | 4;
  action: string;
  color: keyof typeof Colors;
  reason?: string;
}
