import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: 24,
    boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.07)",
    borderRadius: "16px",
    minWidth: "270px",
    minHeight: "290px",
    marginRight: "20px",
    marginBottom: "14px",
    position: "relative",
  },
  appName: {
    color: "rgba(0, 0, 0, 0.7)",
    fontSize: 14,
    fontWeight: 500,
  },
  cardDetails: {
    color: "rgba(0, 0, 0, 0.7)",
    "&.MuiTypography-root": {
      fontSize: 12,
    },
  },
  moreIcon: {
    position: "absolute",
    fontSize: 20,
    color: "rgba(97, 97, 97, 0.8)",
    top: 20,
    right: 18,
  },
}));
