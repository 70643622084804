import { Box, Typography, Button } from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles";
import celebrating from "shared/assets/celebrating.svg";
import { ApplicationData } from "../../types";
import API, { authorizationToken } from "adapters/rest";
import { useHistory } from "react-router-dom";
import useUserData from "modules/useUserData";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function SubmitTab({
  setValue,
  data,
  setOpen,
  isCreatingApp,
  setIsCreatingApp,
}: {
  setValue: (key: number) => void;
  data: ApplicationData;
  setOpen: (key: boolean) => void;
  isCreatingApp: boolean;
  setIsCreatingApp: (key: boolean) => void;
}) {
  const classes = useStyles();
  const history = useHistory();
  const { accessToken } = useUserData();

  const handleSubmit = () => {
    setIsCreatingApp(true);
    const url = `/iam/v1/applications`;
    authorizationToken.set(accessToken);
    API.post(url, data).then((response) => {
      history.push(`/${response.data.result}`);
      setOpen(false);
    });
  };

  return (
    <Box className={classes.tabContentContaner}>
      {/* Tab content */}

      {isCreatingApp ? (
        <Box
          display="flex"
          flexDirection="column"
          height="200px"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            style={{ marginBottom: "20px", fontSize: 24, fontWeight: "500" }}
          >
            Creating your app
          </Typography>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box display="flex" flexDirection="column" mb={4} alignItems="center">
            <Typography className={classes.submitTabTitle}>
              Submit 🥳
            </Typography>
            <Typography className={classes.submitTabSubtitle}>
              Submit to kickstart your project..
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center">
            <img
              style={{ width: "150px", height: "165px" }}
              src={celebrating}
              alt="celebrating image"
            />
          </Box>

          <Box display="flex" mt={6} justifyContent="space-between">
            <Button variant="outlined" onClick={() => setValue(0)}>
              previous
            </Button>
            <Button variant="contained" onClick={handleSubmit} color="primary">
              Submit
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
