import {ModulesPaths} from 'shared/types';

export const StemPaths = {
  //ROOT: ModulesPaths.ROOT,
  HOME: ModulesPaths.THINGS,
  // TEAM: `${ModulesPaths.IOTPASS}/teams/:teamId`,
  // TEAMS: `${ModulesPaths.IOTPASS}/teams`,
  // USER: `${ModulesPaths.IOTPASS}/user/:userId`,
  // USERS: `${ModulesPaths.IOTPASS}/users/`,
  // RESOURCES: `${ModulesPaths.IOTPASS}/usage/`,
  PROJECTS: '/projects',
  PROJECT: ModulesPaths.THINGS,
  CREATE_PROJECT: `${ModulesPaths.THINGS}/projects/create`,
  VISUALIZATIONS: `${ModulesPaths.THINGS}/visualizations`,
  VIS_OVERVIEW: `${ModulesPaths.THINGS}/visualizations/overview`,
  DASHBOARDS_LIST: `${ModulesPaths.THINGS}/visualizations/dashboards`,
  DASHBOARD: `${ModulesPaths.THINGS}/visualizations/:visualizationId`,
  DASHBOARD_STATE: `${ModulesPaths.THINGS}/visualizations/:visualizationId/states/:stateId`,
  ADD_COMPONENT: `${ModulesPaths.THINGS}/visualizations/:visualizationId/states/:stateId/add`,
  SITE_BUILDER: `${ModulesPaths.THINGS}/visualizations/dashboards`,
  SITE_BUILDER_EDITOR: `${ModulesPaths.THINGS}/visualizations/dashboards/:builderId`,
  ADMIN: `${ModulesPaths.THINGS}/admin`,
  // DEVICES: `${ModulesPaths.THINGS}/admin/devices`,
  DEVICES_LABELS: `${ModulesPaths.THINGS}/admin/labels`,
  THINGS: `${ModulesPaths.THINGS}/admin/things`,
  THING_PROFILE: `${ModulesPaths.THINGS}/admin/things/:thingProfileID/profile`,
  THINGS_GROUP: `${ModulesPaths.THINGS}/control/thingsGroups`,
  THINGS_GROUP_PROFILE: `${ModulesPaths.THINGS}/control/thingsGroups/:thingsGroupId/profile`,
  BOOTSTRAPPING_KEYS: `${ModulesPaths.THINGS}/admin/bootstrapping/keys`,
  BOOTSTRAPPING_LOGS: `${ModulesPaths.THINGS}/admin/bootstrapping/logs`,
  MAINTENANCE: `${ModulesPaths.THINGS}/maintenance`,
  MAINTENANCE_WORKORDERS: `${ModulesPaths.THINGS}/maintenance/workOrders`,
  MAINTENANCE_PREVENTIVE_PLANS: `${ModulesPaths.THINGS}/maintenance/preventive/plans`,
  MAINTENANCE_PREVENTIVE_LOGS: `${ModulesPaths.THINGS}/maintenance/preventive/logs`,
  MAINTENANCE_CORRECTIVE_WORKREQUEST: `${ModulesPaths.THINGS}/maintenance/corrective/workRequest`,
  MAINTENANCE_CORRECTIVE_LOGS: `${ModulesPaths.THINGS}/maintenance/corrective/logs`,

  MAINTENANCE_PROCEDURES: `${ModulesPaths.THINGS}/maintenance//Procedures`,
  MONITORING: `${ModulesPaths.THINGS}/monitoring`,
  REPORT: `${ModulesPaths.THINGS}/monitoring/report`,
  OVERVIEW: `${ModulesPaths.THINGS}/monitoring/overview`,
  ALARMS: `${ModulesPaths.THINGS}/monitoring/alarms`,
  EVENTS: `${ModulesPaths.THINGS}/monitoring/events`,
  TELEMETRIES: `${ModulesPaths.THINGS}/monitoring/telemetries`,
  PROJECT_DASHBOARDS: `${ModulesPaths.THINGS}/dashboards`,
  PROJECT_DASHBOARD: `${ModulesPaths.THINGS}/dashboards/:dashboardId`,
  PROJECT_DASHBOARD_VIEWER: `${ModulesPaths.THINGS}/dashboard/:dashboardId/view`,
  CONTROL: `${ModulesPaths.THINGS}/control`,
  RULE_ENGINE: `${ModulesPaths.THINGS}/control/rule_engine`,
  COMMANDS: `${ModulesPaths.THINGS}/control/commands`,
  PROCEDURES: `${ModulesPaths.THINGS}/admin/procedures`,
  COMMANDS_QUEUE: `${ModulesPaths.THINGS}/control/commands/queue`,
  SCHEDULER: `${ModulesPaths.THINGS}/control/scheduler`,
  REPORTING: `${ModulesPaths.THINGS}/integrate/reports`,
  OTA_FILES: `${ModulesPaths.THINGS}/control/ota`,
  CUSTOM_TOPIC: `${ModulesPaths.THINGS}/admin/customTopics`,
  CREDENTIAL_KEYS: `${ModulesPaths.THINGS}/admin/credentialKeys`,
  ALARMSTab: `${ModulesPaths.THINGS}/alarms`,
  ALARMS_TYPES: `${ModulesPaths.THINGS}/admin/alarms/types`,
  SITE_TYPES: `${ModulesPaths.THINGS}/admin/placeTypes`,
  SITE_TYPE_PROFILE: `${ModulesPaths.THINGS}/admin/placeTypes/:placeTypeId/profile`,
  ASSET_TYPES: `${ModulesPaths.THINGS}/admin/assetTypes`,
  ASSET_TYPE_PROFILE: `${ModulesPaths.THINGS}/admin/assetTypes/:assetTypeId/profile`,
  TEAMS: `${ModulesPaths.THINGS}/admin/teams`,
  USERS: `${ModulesPaths.THINGS}/admin/users`,
  SERVICES_TYPES: `${ModulesPaths.THINGS}/admin/servicesTypes`,
  SITES_SITE_TYPES: `${ModulesPaths.THINGS}/placeTypes`,
  SITES_SITE_TYPES_PROFILE: `${ModulesPaths.THINGS}/placeTypes/:profileId`,
  // SITES: `${ModulesPaths.THINGS}/placeTypes/:typeId/places`,
  SITE_PROFILE: `${ModulesPaths.THINGS}/placeTypes/:profileId/places/:placeId/profile`,
  ALARMSTYPES_PROFILE: `${ModulesPaths.THINGS}/admin/alarms/types/:typeId/profile`,
  ALARMS_DASHBOARD: `${ModulesPaths.THINGS}/admin/alarms/dashboard`,
  // INVENTORY: `${ModulesPaths.THINGS}/inventory`,

  ASSETS: `${ModulesPaths.THINGS}/assetThingProfile`,
  ASSETS_THING_PROFILE: `${ModulesPaths.THINGS}/assetThingProfile/:profileId`,
  // INVENTORY_ASSETS_ThingProfile: `${ModulesPaths.THINGS}/assetsThingProfiles/:thingProfileId`,
  ASSET_PROFILE: `${ModulesPaths.THINGS}/assetThingProfile/:profileId/assets/:assetId`,

  DEVICES: `${ModulesPaths.THINGS}/thingProfile`,
  DEVICES_THING_PROFILE: `${ModulesPaths.THINGS}/thingProfile/:profileId`,
  DEVICES_THING_PROFILE_DASHBOARD: `${ModulesPaths.THINGS}/thingProfile/:profileId/dashboard`,
  THING_PROFILE_DASHBOARD: `${ModulesPaths.THINGS}/thingProfile/:profileId/things/:thingId/dashboard`,
  DEVICE: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId`,
  DEVICE_DETAILS: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId/details`,
  DEVICE_ATTRIBUTES: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId/attributes`,
  DEVICE_PERIPHERALS: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId/peripheral`,
  DEVICE_TELEMETRIES: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId/telemetries`,
  DEVICE_ALARMS: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId/alarms`,
  DEVICE_EVENTS: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId/events`,
  DEVICE_GPIOs: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId/gpios`,
  IO_DEVICE: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId/gpios/:ioId`,
  IO_DEVICE_TELEMETRIES: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId/gpios/:ioId/telemetries`,
  IO_DEVICE_ALARMS: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId/gpios/:ioId/alarms`,
  IO_DEVICE_EVENTS: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId/gpios/:ioId/events`,
  IO_DEVICE_DATA_EXPORT: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId/gpios/:ioId/data_export`,
  DEVICE_COMMANDS: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId/commands`,
  DEVICE_DATA_EXPORT: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId/data_export`,
  DEVICE_TOPICS: `${ModulesPaths.THINGS}/thingProfile/:profileId/devices/:deviceId/topics`,
};
