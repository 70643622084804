import React from 'react';
import {Box, Typography} from '@material-ui/core';
import {useStyles} from './styles';
import WidgetsIcon from '@material-ui/icons/Widgets';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {useHistory} from 'react-router-dom';
// import StarBorderIcon from '@mui/icons-material/StarBorder';
// import StarIcon from '@mui/icons-material/Star';
// import GroupsIcon from "@mui/icons-material/Groups";
// import LayersIcon from "@mui/icons-material/Layers";
// import Chip from '@mui/material/Chip';

export interface userInformation {
  id: string;
  name: string;
  email: string;
  isRevoke: boolean;
  createdAt: string;
  updatedAt: string;
  status: string;
}

export interface App {
  id: string;
  name: string;
  createdAt: string;
  ownerId: string;
  plan: string;
  // isStarred: boolean;
  //inTrial: boolean;
  updatedAt: string;
  planId: string;
  userInformation: userInformation;
}

interface Props extends App {
  ref: any;
}

export default function Application({
  name,
  createdAt,
  planId,
  // isStarred,
  userInformation,
  id,
}: App) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.card}>
      <MoreVertIcon className={classes.moreIcon} />
      <Box style={{padding: '25px 0px', display: 'flex', justifyContent: 'center'}}>
        <WidgetsIcon color="primary" fontSize="large" />
      </Box>
      <Box display="flex" alignItems="start">
        <Typography
          onClick={() => history.push(`/${id}/placeTypes`)}
          style={{cursor: 'pointer', marginRight: '15px'}}
        >
          {name}
        </Typography>
        {/* {isFav ? (
                    <StarIcon onClick={() => setIsFav(false)} fontSize="small" sx={{ color: "rgb(255, 152, 0)" }} />
                ) : (
                    <StarBorderIcon
                        sx={{ color: "rgba(97, 97, 97, 0.8)" }}
                        fontSize="small"
                        onClick={() => setIsFav(true)}
                    />
                )} */}
      </Box>

      <Typography className={classes.cardDetails} style={{margin: '24px 0px 8px 0px'}}>
        Created at: {createdAt}
      </Typography>

      <Typography className={classes.cardDetails} style={{marginBottom: '40px'}}>
        Owned by: {userInformation.email}
      </Typography>

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography style={{marginLeft: '10px'}} className={classes.cardDetails}>
          {planId}
        </Typography>

        {/* {inTrial && (
                    <Chip variant='outlined' label='In trial' />
                )} */}
      </Box>
    </Box>
  );
}
