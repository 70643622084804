import React, {useCallback, useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useHistory, useParams} from 'react-router-dom';
import {getProjects} from 'modules/IotPaaS/api/Projects';
import {StemPaths} from 'modules/IotPaaS/types/index';
import {injectParamsIntoUrl} from 'shared/utils';
import {CircularLoader} from 'shared/components/Loaders/CircularLoader';
import useInstanceData from 'modules/useInstanceData';
import {Project} from 'modules/IotPaaS/types/Projects';

export default function ProjectsSelect() {
  const history = useHistory();
  const params = useParams<{projectId?: string; accountId: string}>();
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const {instance} = useInstanceData();
  const {projectId} = params;

  const handleSelectProject = useCallback(
    (event, data) => {
      const path = injectParamsIntoUrl(StemPaths.PROJECT, {
        ...params,
        projectId: data.id,
      });
      history.push(path);
    },
    [history, params],
  );

  useEffect(() => {
    if (!instance) {
      return;
    }
    setIsLoading(true);
    getProjects(instance.id)
      .then(projects => {
        setProjects(projects.result);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [instance]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Autocomplete
      value={projects.find(({id}) => id === projectId)}
      options={projects}
      getOptionLabel={option => option.name}
      style={{width: 150}}
      size="small"
      renderInput={params => <TextField {...params} label="Projects" variant="outlined" />}
      disableClearable={true}
      onChange={handleSelectProject}
    />
  );
}
