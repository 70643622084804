import {makeStyles} from '@material-ui/core/styles';

export const useCommonStyles = makeStyles(theme => ({
  textField: {
    '& label': {
      color: theme.palette.secondary.dark,
      transform: 'translate(14px, 17px) scale(1)',
    },
    '& label.Mui-focused': {
      color: theme.palette.secondary.light,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.secondary.light,
    },
    '& .MuiOutlinedInput-root': {
      background: theme.palette.secondary.shades[0],
      '&.Mui-focused': {
        background: 'transparent',

        '& fieldset': {
          borderColor: theme.palette.secondary.light,
        },
      },

      '&:hover fieldset': {
        borderColor: theme.palette.secondary.light,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(3),
    },
  },
  fullWidth: {
    width: '100%',
  },
  halfWidthTxt: {
    width: '48%',
  },
  mrT5: {
    marginTop: theme.spacing(1),
  },
  mrT10: {
    marginTop: theme.spacing(2),
  },
  mrT20: {
    marginTop: theme.spacing(4),
  },
  mrB5: {
    marginBottom: theme.spacing(1),
  },
  mrB10: {
    marginBottom: theme.spacing(2),
  },
  mrB15: {
    marginBottom: theme.spacing(3),
  },
  mrB20: {
    marginBottom: theme.spacing(4),
  },
  mrB30: {
    marginBottom: theme.spacing(6),
  },
  mrV10: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mrV20: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  mrStart5: {
    marginLeft: theme.spacing(1),
  },
  mrStart10: {
    marginLeft: theme.spacing(2),
  },
  mrStart20: {
    marginLeft: theme.spacing(4),
  },
  mrEnd5: {
    marginRight: theme.spacing(1),
  },
  mrEnd10: {
    marginRight: theme.spacing(2),
  },
  mrEnd20: {
    marginRight: theme.spacing(4),
  },
  mrAuto: {
    marginRight: 'auto',
  },
  mlAuto: {
    marginLeft: 'auto',
  },
  upperTxt: {
    textTransform: 'uppercase',
  },
  capitalizeTxt: {
    textTransform: 'capitalize',
  },
  lowerTxt: {
    textTransform: 'lowercase',
  },
  boldF: {
    fontWeight: 500,
  },
  lightF: {
    fontWeight: 400,
  },
  mediumF: {
    fontSize: '0.9em',
  },
  smallF: {
    fontSize: '0.7em',
  },
  pointer: {
    cursor: 'pointer',
  },
  divider: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    height: 2,
  },
  imgWidth: {
    maxWidth: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  title: {
    color: theme.palette.secondary.shades[5],
  },
  titleModule: {
    borderBottom: `2px solid ${theme.palette.grey[200]}`,
    color: theme.palette.primary.shades[5],
    padding: theme.spacing(3, 0),
    marginBottom: `${theme.spacing(4)}px !important`,
  },
  customCheckBox: {
    padding: theme.spacing(1, 3),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.secondary.shades[4],
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    '&.selected': {
      background: theme.palette.secondary.shades[2],
    },
  },
  addBtn: {
    width: 90,
    height: 90,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.grey[100],
    border: `2px dashed ${theme.palette.grey[400]}`,
    color: theme.palette.grey[400],

    '&.small': {
      width: 25,
      height: 25,
      borderWidth: 1,
    },
    '&.primary': {
      borderColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.dark,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  boxLight: {
    border: `1px solid ${theme.palette.secondary.shades[2]}`,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.common.white,
    padding: theme.spacing(3),
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',

    '& .child': {
      width: '49%',
    },
    '& .child:last-child': {
      borderLeft: `1px solid ${theme.palette.secondary.shades[2]}`,
      paddingLeft: theme.spacing(3),
    },
    '&.oneThird': {
      '& .child': {
        '&:first-child': {
          width: '60%',
          padding: theme.spacing(0, 5),
        },
        '&:last-child': {
          width: '40%',
          borderLeft: 'none',
        },
      },
    },

    '&.noPadd': {
      padding: 0,
    },
    '&.lighter': {
      borderColor: theme.palette.secondary.shades[4],
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
  },
  tabButton: {
    background: theme.palette.secondary.shades[4],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.8, 2),
    color: theme.palette.primary.main,
    fontSize: '0.8rem',
    display: 'flex',
    minWidth: 120,
    transition: 'all 0.4s',

    '&:hover': {
      background: theme.palette.secondary.shades[2],
    },
    '&.selected': {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    '& + $tabButton': {
      marginLeft: theme.spacing(2),
    },
    '& .icon': {
      marginRight: theme.spacing(1),
    },
  },
  listTabBtn: {
    background: theme.palette.secondary.shades[4],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    color: theme.palette.primary.main,
    fontSize: '0.8rem',

    '&.selected': {
      background: theme.palette.secondary.shades[2],
      color: theme.palette.primary.light,
    },
    '&.disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    '& + $listTabBtn': {
      marginLeft: theme.spacing(1),
    },
  },
  circle: {
    borderRadius: '50%',
    transition: '.4s all',

    '&.tiny': {
      width: 10,
      height: 10,
    },
    '&.small': {
      width: 20,
      height: 20,
    },
    '&.selected': {
      border: '5px solid',
    },
    '& + $circle': {
      marginLeft: theme.spacing(2),
    },
    '&.selectable': {
      cursor: 'pointer',
    },
  },
  picker: {
    background: theme.palette.secondary.shades[13],
    borderRadius: theme.shape.borderRadius,
    marginTop: '0 !important',
    marginBottom: '0 !important',

    '& .MuiInputBase-root': {
      fontSize: '.8rem',
      padding: 5,
    },
    '& .MuiInput-underline': {
      '&:before , &:after': {
        display: 'none',
      },

      '& .MuiInputBase-input': {
        padding: 0,
        width: 80,
      },
    },

    '& input[type="date" i]::-webkit-calendar-picker-indicator': {
      margin: 0,
    },
    '& .MuiInputAdornment-positionEnd': {
      margin: 0,

      '& .MuiIconButton-root': {
        padding: 2,

        '& .MuiSvgIcon-root': {
          fontSize: '1rem',
        },
      },
    },

    '&.time': {
      '& .MuiInput-underline': {
        '& .MuiInputBase-input': {
          width: 60,
        },
      },
    },
    '&.fullWidth': {
      '& .MuiInput-underline': {
        '& .MuiInputBase-input': {
          width: '100%',
        },
      },
    },
    '& + $picker': {
      marginLeft: theme.spacing(4),
    },
  },
  numberField: {
    background: theme.palette.secondary.shades[13],
    borderRadius: theme.shape.borderRadius,
    width: 80,
    marginRight: '15px !important',

    '& .MuiInput-underline': {
      '&:before , &:after': {
        display: 'none',
      },

      '& .MuiInputBase-input': {
        padding: 7,
      },
    },
  },
  selectField: {
    background: theme.palette.secondary.shades[13],
    borderRadius: theme.shape.borderRadius,
    width: 80,

    '& .MuiInput-underline': {
      '&:before , &:after': {
        display: 'none',
      },
      '& .MuiInputBase-input': {
        padding: 7,
      },
    },
    '&.fullWidth': {
      width: '100%',
    },
  },
  ActionBox: {
    padding: theme.spacing(3, 4),
    border: `1px solid ${theme.palette.secondary.shades[4]}`,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.secondary.shades[4],
    },
    '&.selected': {
      backgroundColor: theme.palette.secondary.shades[10],
      color: theme.palette.common.white,
      '& $optionCardIconContainer': {
        background: 'transparent',
      },
      '& $optionCardIcon': {
        color: theme.palette.common.white,
      },
      '&:hover': {
        '& $optionCardIconContainer': {
          background: 'transparent',
        },
      },
    },
    '&.active': {
      backgroundColor: theme.palette.secondary.shades[10],
      color: theme.palette.common.white,
      cursor: 'initial',
    },
    '&.condition': {
      width: '80%',
    },
    '&.noPadd': {
      padding: theme.spacing(0),
    },
  },
  tag: {
    width: 'inherit',
    marginRight: theme.spacing(1),
  },
  customCheckBox1: {
    padding: theme.spacing(1.5),
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    cursor: 'pointer',
    borderRadius: 6,
    '&.disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    '&.selected': {
      borderColor: theme.palette.primary.main,
    },
    '& .icon': {
      marginRight: 8,
      color: 'rgba(97, 97, 97, 0.8)',
    },
    '& .text': {
      color: `rgba(0, 0, 0, 0.87)`,
    },
  },
}));

export const formStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  stepper: {
    '&.MuiStepper-root': {
      padding: theme.spacing(2),
    },
  },
  title: {
    color: theme.palette.secondary.shades[5],
    '&.main': {
      borderBottom: `2px solid ${theme.palette.grey[200]}`,
      paddingBottom: theme.spacing(2),
    },
    '&.small': {
      fontSize: '.8rem',
    },
  },
  scrollable: {
    maxHeight: 260,
    overflow: 'auto',
    paddingRight: theme.spacing(2),

    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      // boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[500],
      borderRadius: theme.shape.borderRadius,
      outline: '1px solid slategrey',
    },
  },
  optionCard: {
    border: `1px solid ${theme.palette.secondary.shades[4]}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.5s',
    cursor: 'pointer',

    '&:hover': {
      // backgroundColor: theme.palette.secondary.shades[4],
      backgroundColor: '#e3f2fe',

      '& $optionCardIconContainer': {
        backgroundColor: theme.palette.primary.shades[2],
      },
    },

    '&.selected': {
      backgroundColor: theme.palette.secondary.shades[10],
      color: theme.palette.common.white,

      '& $optionCardIconContainer': {
        background: 'transparent',
      },
      '& $optionCardIcon': {
        color: theme.palette.common.white,
      },
      '& .details': {
        color: theme.palette.common.white,
      },
      '&:hover': {
        '& $optionCardIconContainer': {
          background: 'transparent',
        },
      },
    },

    '&.large': {
      padding: theme.spacing(1.5),
      // boxShadow: `0px 0px 5px rgba(55,84,170,0.2)`,
      border: '1px solid #dadce0',
      marginBottom: theme.spacing(2),
      '& + $optionCard': {
        marginTop: theme.spacing(3),
      },

      '& $optionCardIconContainer': {
        width: '67px',
        height: '74px',
      },

      '&:hover': {
        '& $optionCardIconContainer': {
          backgroundColor: 'inherit',
        },
      },
    },

    '&.vertical': {
      display: 'block',

      '& .name': {
        padding: theme.spacing(2, 3),
        borderBottom: `1px solid ${theme.palette.secondary.shades[4]}`,
      },

      '& .details': {
        color: theme.palette.primary.light,

        '& .lbl': {
          opacity: 0.7,
          width: 70,
        },
      },
    },

    '& + $optionCard': {
      marginTop: theme.spacing(2),
    },
  },
  optionCardIconContainer: {
    width: '55px',
    height: '55px',
    // backgroundColor: theme.palette.secondary.shades[4],
    background: '#e3f2fe',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.3s',
  },
  optionCardIcon: {
    color: theme.palette.primary.shades[4],
  },
  button: {
    '&.Mui-disabled': {
      visibility: 'hidden',
      opacity: 0,
    },
  },
  colors: {
    width: 20,
    height: 20,
    borderRadius: '50%',

    '& + $colors': {
      marginLeft: theme.spacing(1),
    },
  },
  drawer: {
    width: '30%',
    padding: theme.spacing(4, 3, 0),
  },
  box: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
  },
  boxFilled: {
    color: theme.palette.primary.light,
    background: theme.palette.secondary.shades[7],
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
  },
  formControl: {
    '& + $formControl': {
      marginTop: theme.spacing(4),
    },
  },
  selectFormField: (props?: {fullWidth?: boolean}) => ({
    width: props?.fullWidth ? '100%' : '50%',
    margin: theme.spacing(2, 0),
    minWidth: 100,

    '& .MuiSelect-select': {
      color: theme.palette.secondary.dark,
      '& .MuiSelect-icon': {
        color: theme.palette.secondary.dark,
      },
      '&:focus': {
        background: 'none',
      },
    },
    '& label': {
      color: theme.palette.secondary.dark,
    },
    '& label.Mui-focused': {
      color: theme.palette.primary.shades[0],
    },
    '& .MuiFilledInput-underline': {
      background: theme.palette.secondary.shades[4],
      // color:theme.palette.primary.shades[2],
      color: theme.palette.secondary.dark,
      '&::before': {
        borderBottom: 'none',
      },
      '&::after': {
        borderBottom: 'none',
      },
      '& .MuiSelect-filled': {
        paddingTop: theme.spacing(2),
      },
    },
  }),
  formControlLbl: {
    '&.MuiTypography-root': {
      fontSize: '0.8rem',
      color: theme.palette.secondary.shades[5],
    },
  },
  typesCard: {
    background: '#f5f5f5',
    borderRadius: 22,
    padding: theme.spacing(2),
    width: 95,
    height: 95,
    border: `1px solid #f5f5f5`,
    '&:hover': {
      background: '#e8f0fe',
    },
    '&.selected': {
      background: '#e8f0fe',
      borderColor: theme.palette.primary.main,
    },
    '&.small': {
      width: 62,
      height: 62,
    },
  },
  typesCardIcon: {
    color: theme.palette.primary.main,
    width: '100%',
    height: '100%',

    '& > svg': {
      width: '100%',
      height: '100%',
    },
  },
  btn: {
    '& + $btn': {
      marginLeft: theme.spacing(1),
    },
  },
  inActiveTxt: {
    color: 'rgba(0,0,0,.34)',
    '&[class *= "colorSecondary"]': {
      color: 'rgba(0,0,0,.34)',
    },
  },
}));

export const cardStyles = makeStyles(theme => ({
  mrAuto: {
    marginRight: 'auto',
  },
  box: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    width: '24%',
    position: 'relative',

    '&.checked': {
      borderColor: theme.palette.secondary.shades[5],

      '& $check': {
        transform: 'scaleX(1)',
        maxWidth: 50,
        paddingLeft: 9,
        paddingRight: 9,

        '&.MuiCheckbox-root': {
          color: theme.palette.secondary.main,

          '&.Mui-checked': {
            color: theme.palette.secondary.main,
          },
        },
      },

      '&:hover': {
        color: theme.palette.secondary.main,

        '& $actions': {
          opacity: 0,
          visibility: 'hidden',
        },

        '& $name': {
          color: theme.palette.secondary.main,

          '&:after': {
            background: theme.palette.secondary.shades[8],
          },
        },
      },
    },

    '&:hover': {
      color: theme.palette.common.white,

      '& $actions': {
        opacity: 1,
        visibility: 'visible',
      },

      '& $name': {
        color: theme.palette.common.white,
        '&:after': {
          background: 'transparent',
        },
      },

      '& $check': {
        transform: 'scaleX(1)',
        maxWidth: 50,
        paddingLeft: 9,
        paddingRight: 9,
      },
    },
  },
  check: {
    transform: 'scaleX(0)',
    maxWidth: 0,
    transition: 'all 0.2s',
    transformOrigin: 'left',
    zIndex: 2,
    paddingLeft: 0,
    paddingRight: 0,

    '&.MuiCheckbox-root': {
      color: theme.palette.common.white,

      '&.Mui-checked': {
        color: theme.palette.common.white,
      },
    },
  },
  name: {
    color: theme.palette.secondary.shades[5],
    marginBottom: theme.spacing(3),
    position: 'relative',
    zIndex: 2,
    wordBreak: 'break-word',
    marginLeft: 0,

    '&:after': {
      bottom: 0,
      left: 20,
      width: 30,
      height: 3,
      content: "''",
      position: 'absolute',
      background: theme.palette.secondary.shades[8],
    },
  },
  goToLink: {
    color: 'inherit',
  },
  protocol: {
    position: 'relative',
    paddingTop: theme.spacing(1),

    '&::after': {
      content: "''",
      height: 3,
      width: 30,
      background: theme.palette.secondary.shades[8],
      position: 'absolute',
      top: -10,
      left: 0,
    },

    '& .txt': {
      background: '#e3e4ed',
      border: '1px solid #b8bcd3',
      padding: '2px 5px',
      borderRadius: theme.shape.borderRadius,
    },
  },
  lbl: {
    color: theme.palette.primary.shades[6],
    marginBottom: 3,
    marginTop: 15,
    display: 'block',
  },
  txt: {
    color: theme.palette.primary.light,
  },
  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.accent.shades[1],
  },
  lowerSection: {
    background: theme.palette.secondary.shades[0],
    minHeight: 70,
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    opacity: 0,
    visibility: 'hidden',
    background: theme.palette.primary.shades[7],
    transition: 'all 0.4s',
    borderRadius: theme.shape.borderRadius,
    zIndex: 1,
    paddingTop: theme.spacing(10),

    '& .lower': {
      background: theme.palette.primary.shades[7],
      padding: 7,
    },
  },
  actionIcon: {
    background: theme.palette.common.white,
    borderRadius: '50%',
    color: theme.palette.secondary.dark,
    marginBottom: theme.spacing(1),

    '&:hover': {
      background: theme.palette.secondary.shades[4],
      color: theme.palette.secondary.shades[5],
    },

    '& + $actionIcon': {
      marginLeft: theme.spacing(2),
    },
  },
}));
