import React, {ReactElement} from 'react';
import {createMuiTheme, responsiveFontSizes, ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {useTranslation} from 'react-i18next';

const muiTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#091854',
      light: '#202e72',
      contrastText: '#fff',
      shades: [
        '#395699',
        '#122060',
        '#3da2fa',
        'rgba(32, 46, 114, .33)',
        'rgba(32, 46, 114, .7)',
        '#1b286a',
        '#606898',
        'rgba(1, 5, 63, .9)',
        'rgba(9, 24, 84, 0.6)',
        '#01053f',
        '#1034a6',
        '#133cbf',
        '#E8F0FE',
        '#0E2B95',
      ],
    },
    secondary: {
      main: '#1243a5',
      light: '#1a85ea',
      dark: '#414a85',
      contrastText: '#fff',
      shades: [
        'rgba(227, 228, 237, .3)',
        'rgb(236, 247, 255)',
        '#baddfd',
        'rgba(236, 247, 255, .7)',
        '#e3f2fe',
        '#1873d6',
        '#1662c4',
        'rgba(186, 221, 253,.5)',
        '#007afc',
        '#61b3fa',
        '#1893f8',
        'rgba(227, 242, 254, 0.2)',
        '#8ec8fc',
        'rgba(227, 242, 254, 0.5)',
        `#e0e0e0`,
      ],
    },
    accent: {
      main: '#f133a7',
      dark: '#a60066',
      contrastText: '#fff',
      shades: ['#ed0091', '#f466bd', '#fab3de', '#be0074', '#ffd1ec', '#fc179d', '#a10063'],
    },
    highlight: {
      main: '#30ff99',
      dark: '#2ce689',
      contrastText: '#091854',
      shades: [],
    },
    error: {
      main: '#b00020',
      light: '#ff5722',
      contrastText: '#fff',
      shades: ['#fff3e0'],
    },
    extra: {
      orange: '#ff9800',
      orangeT: 'rgb(255 152 0 / .12)',
      pink: '#e91e63',
      pinkT: 'rgb(233 30 99 / .12)',
      purple: '#673ab7',
      purpleT: 'rgb(103 58 183 / .12)',
      green: '#009688',
      greenT: 'rgb(0 150 136 / .12)',
      link: '#133cbf',
    },
    danger: {
      main: '#d8232a',
      contrastText: '#fff',
      shades: ['rgba(216, 35, 42, .1)'],
    },
    success: {
      main: '#468966',
      contrastText: '#fff',
      shades: [],
    },
    warning: {
      main: '#f5d547',
      contrastText: '#091854',
      shades: ['rgba(245, 213, 71, .2)', '#ff8400', 'rgba(240, 240, 134, .7)'],
    },
    safe: {
      main: '#00e676',
      contrastText: '#091854',
      shades: [],
    },
    text: {
      primary: '#122060',
      secondary: '#414a85',
      hint: '#ed0091',
    },
    background: {
      paper: '#fff',
      default: '#fff',
      // gradient: 'linear-gradient(42deg, rgba(74,0,104,1) 0%, rgba(2,5,64,1) 66%)',
    },
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 14,

    h1: {
      fontSize: '6rem',
      letterSpacing: '-1px',
      textTransform: 'capitalize',
    },
    h2: {
      fontSize: '4.5rem',
      textTransform: 'capitalize',
    },
    h3: {
      fontSize: '2.5rem',
      textTransform: 'capitalize',
    },
    h4: {
      fontSize: '2.125rem',
      textTransform: 'capitalize',
    },
    h5: {
      fontSize: '1.35rem',
      textTransform: 'capitalize',
    },
    h6: {
      fontSize: '1.125rem',
      textTransform: 'capitalize',
    },
    subtitle1: {
      fontSize: '1rem',
    },
    subtitle2: {
      fontSize: '.875rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '1.0625rem',
    },
    body2: {
      fontSize: '.875rem',
    },
    button: {
      fontSize: '.875rem',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: '.8rem',
    },
    overline: {
      fontSize: '.7rem',
      // fontSize: '.625rem',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      lineHeight: '1',
    },
  },
  spacing: 5,
  custom: {
    headerHeight: 0,
    // headerHeight: 60,
    drawerWidth: 320,
    sideNavWidthClosed: 75,
    sideNavWidthOpened: 230,
  },
});

interface Props {
  children: React.ReactNode;
}

function CervelloTheme(props: Props): ReactElement {
  const {i18n} = useTranslation();
  const appDirection = i18n.dir();

  return (
    <ThemeProvider theme={{...responsiveFontSizes(muiTheme), direction: appDirection}}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}

export default CervelloTheme;
