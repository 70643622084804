import React, {ReactElement} from 'react';
import {Box, Button, Typography, Link} from '@material-ui/core';

import {useTranslation} from 'react-i18next';
import Header from '../../../modules/Layout/Header';
import Error404 from './Error404.svg';

function ErrorPage404(): ReactElement {
  const {t} = useTranslation();
  // const {homeUrl, instanceName} = props || {};

  return (
    <div>
      {/*<Layoutcc sideMenuItems={sideMenu && BottomItems} showProjectsDropdown={true}>*/}
      <Header
        // setMenuIsOpen={setMenuIsOpen}
        // menuIsOpen={menuIsOpen}
        showProjectsDropdown={false}
      />
      <Box width={'100vw'} height={'calc(100vh - 64px)'} display="flex" justifyContent="center">
        <Box display="flex" justifyContent="center" flexDirection="column" width="25vw">
          <Box height="40vh">
            <img src={Error404} width="100%" height="100%" />
          </Box>
          <Box mt={4} style={{textAlign: 'center'}}>
            <Typography variant="h4" style={{fontFamily: 'Poppins', fontWeight: 500}}>
              {t('general.not_found')}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" mt={4}>
            <Link href='/'>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                style={{paddingBottom: 3, paddingTop: 3}}
              >
                {t('general.go_to_home_page')}
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>

      {/*</Layoutcc>*/}
    </div>

  );
}

export default ErrorPage404;
