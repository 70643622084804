import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    boxShadow: "rgb(50 71 92 / 10%) 0px 2px 10px",
    borderRadius: 8,
    backgroundColor: "#ffff",
    padding: "45px",
    minWidth: "700px",
    position: "relative",
  },

  title: {
    "&.MuiTypography-root": {
      fontSize: 28,
      color: "rgba(50, 71, 92, 0.87)",
      fontWeight: "500",
      marginBottom: 12,
    },
  },
  subtitle: {
    "&.MuiTypography-root": {
      fontSize: 18,
      color: "rgba(50, 71, 92, 0.6)",
      fontWeight: "400",
    },
  },
  tabContainer: {
    display: "flex",
    marginBottom: "25px",
    cursor: "pointer",
  },
  tabIcon: {
    color: "rgba(50, 71, 92, 0.6)",
    fontSize: 24,
  },
  activeTabIcon: {
    color: "#FFFF",
    fontSize: 28,
  },
  avatar: {
    padding: "20px",
    marginRight: "16px",
    "&.MuiAvatar-root": {
      borderRadius: "6px",
    },
    "&.MuiAvatar-colorDefault": {
      backgroundColor: "rgba(50, 71, 92, 0.08)",
    },
  },
  activeAvatar: {
    padding: "20px",
    marginRight: "16px",
    "&.MuiAvatar-root": {
      borderRadius: "6px",
    },
    "&.MuiAvatar-colorDefault": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  tabTitle: {
    "&.MuiTypography-root": {
      fontSize: 16,
      color: "rgba(50, 71, 92, 0.87)",
      textTransform: "uppercase",
    },
  },
  tabSubtitle: {
    "&.MuiTypography-root": {
      fontSize: 13,
      color: "rgba(50, 71, 92, 0.6)",
      fontWeight: "500",
      whiteSpace: "nowrap",
    },
  },

  tabContentContaner: {
    padding: "5px 20px 10px 40px",
    alignItems: "center",
    width: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  submitTabTitle: {
    "&.MuiTypography-root": {
      fontSize: 20,
      color: "rgba(50, 71, 92, 0.87)",
      fontWeight: "500",
      marginBottom: 6,
    },
  },
  submitTabSubtitle: {
    "&.MuiTypography-root": {
      fontSize: 16,
      color: "rgba(50, 71, 92, 0.6)",
      fontWeight: "400",
    },
  },
  closeIcon: {
    position: "absolute",
    top: 20,
    right: 15,
    cursor: "pointer",
  },
}));
