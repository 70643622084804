import create, {StateCreator} from 'zustand';
import {devtools} from 'zustand/middleware';
import {Subscriptions} from './types';

interface State {
  projectId: string;
  subscriptionId: string;
  subscriptions: Subscriptions;
  subscriptionPlanId: string;

  setSubscriptions: (newSubscriptions: Subscriptions) => void;
  setProjectId: (projectId: string) => void;
  setSubscriptionId: (subscriptionId: string) => void;
  setSubscriptionPlanId: (subscriptionPlanId: string) => void;
}

const initialValues: StateCreator<State> = set => ({
  subscriptions: {
    maintenance: false,
    dataTransformation: false,
    protocols: [],
    policies: false,
    dashboardsPublicAccess: false,
    reportsAPIs: false,
    customRuleComponent: false,
    protectiveMaintenance: false,
    commissioning: false,
    provisioning: false,
    assets: false,
    thingProfiles: false,
  },
  projectId: '',
  subscriptionId: '',
  subscriptionPlanId: '',

  setSubscriptions: subscriptions => {
    set(state => {
      return {
        ...state,
        subscriptions,
      };
    });
  },
  setProjectId: projectId => {
    set(state => {
      return {
        ...state,
        projectId,
      };
    });
  },
  setSubscriptionId: subscriptionId => {
    set(state => {
      return {
        ...state,
        subscriptionId,
      };
    });
  },
  setSubscriptionPlanId: subscriptionPlanId => {
    set(state => {
      return {
        ...state,
        subscriptionPlanId,
      };
    });
  },
});

const state = create<State>(devtools(initialValues, 'subscriptions'));
export default state;
