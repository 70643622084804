import React, {ReactElement} from 'react';
import CervelloTheme from './themes';
import {BrowserRouter} from 'react-router-dom';
import {renderRoutes, RouteConfig} from 'react-router-config';
import IotPaaS from 'modules/IotPaaS/';
import Root from './Root';
import ErrorPage404 from 'shared/components/ErrorPages/404';
import {ModulesPaths} from 'shared/types/';
import ErrorPage403 from '../shared/components/ErrorPages/403';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto';
import './styles.css';
import Apps from 'modules/IotPaaS/LandingPage/Apps';
import flagsmith from 'flagsmith';
import {FlagsmithProvider} from 'flagsmith/react';
import Loader from '../shared/components/Loaders/GlobalLoader';

const {VITE_FLAGSMITH_API, VITE_FLAGSMITH_ENV} = (window as any).env;

const routes: RouteConfig[] = [
  {
    component: Root,
    routes: [
      {
        path: ModulesPaths.ROOT,
        // name: 'LandingPage',
        // exact: true,
        // component: LandingPage,
        name: 'Root',
        exact: true,
        component: Apps,
      },
      {
        path: '/403',
        exact: true,
        component: ErrorPage403,
      },
      {
        path: ModulesPaths.THINGS,
        name: 'Things',
        component: IotPaaS,
      },
      {
        path: '*',
        component: ErrorPage404,
      },
    ],
  },
];

function App(): ReactElement {
  const renderedRoutes = renderRoutes(routes);
  return (
    <React.Suspense fallback={<Loader />}>
      <FlagsmithProvider
        options={{
          api: VITE_FLAGSMITH_API,
          environmentID: VITE_FLAGSMITH_ENV,
          cacheFlags: true,
        }}
        flagsmith={flagsmith}
      >
        <CervelloTheme>
          <BrowserRouter>{renderedRoutes}</BrowserRouter>
        </CervelloTheme>
      </FlagsmithProvider>
    </React.Suspense>
  );
}

export default App;
