import create, {StateCreator} from 'zustand';
import {devtools} from 'zustand/middleware';
// import {Subscriptions} from './types';
import Keycloak from 'keycloak-js/dist/keycloak';
import {Permission} from '../type';

interface State {
  accessToken: string;
  permissions: Permission[];
  setAccessToken: (accessToken: string | undefined) => void;
  setPermissions: (permissions: Permission[]) => void;
}

const initialValues: StateCreator<State> = set => ({
  accessToken: '',
  permissions: [],
  setAccessToken: accessToken => {
    set(state => {
      return {
        ...state,
        accessToken: accessToken || '',
      };
    });
  },
  setPermissions: permissions => {
    set(state => {
      return {
        ...state,
        permissions,
      };
    });
  },
});

const state = create<State>(devtools(initialValues, 'authorization'));
export default state;
