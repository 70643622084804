import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../../modules/Layout/Header';
import { Box, Button, Link, Typography } from '@material-ui/core';
import Error403 from './Error403.svg';
import { useSubscriptionsStore } from 'shared/store';
import { injectParamsIntoUrl } from 'shared/utils';
import { StemPaths } from 'modules/IotPaaS/types/index';


const ErrorPage403 = () => {
  const { t } = useTranslation();
  const { projectId } = useSubscriptionsStore();

  return (
    <div>
      {/*<Layoutcc sideMenuItems={sideMenu && BottomItems} showProjectsDropdown={true}>*/}
      <Header
        // setMenuIsOpen={setMenuIsOpen}
        // menuIsOpen={menuIsOpen}
        showProjectsDropdown={false}
      />
      <Box width={'100vw'} height={'calc(100vh - 64px)'} display="flex" justifyContent="center">
        <Box display="flex" justifyContent="center" flexDirection="column" width="25vw">
          <Box height="40vh">
            <img src={Error403} width="100%" height="100%" />
          </Box>
          <Box mt={4} style={{ textAlign: 'center' }}>
            <Typography variant="h4" style={{ fontFamily: 'Poppins', fontWeight: 500 }}>
              {t('general.forbidden_message')}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" mt={4}>
            <Link href={`${injectParamsIntoUrl(StemPaths.HOME, {
              projectId,
            })}`}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                style={{ paddingBottom: 3, paddingTop: 3 }}
              >
                {t('general.go_to_home_page')}
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>

      {/*</Layoutcc>*/}
    </div>
  );
};

export default ErrorPage403;
