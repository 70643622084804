import {useCallback, useEffect, useState} from 'react';
import {createGlobalState} from 'react-hooks-global-state';
import Keycloak from 'keycloak-js';
import KeycloakAuthorization from 'keycloak-js/dist/keycloak-authz';
import {User, Preferences, UserDataHook} from './types';
import {authorizationToken} from '../../adapters/rest';
import jwt_decode from 'jwt-decode';
import {useParams} from 'react-router-dom';
import {useAuthorizationStore} from './store';
import {Permission} from './type';

const {REACT_APP_KC_HOSTNAME, REACT_APP_KC_REALM, REACT_APP_KC_CLIENT_ID} = (window as any).env;

type GlobalState = {
  user?: User;
  // accessToken?: string;
  autClient?: Keycloak;
  keycloakAuthorization?: KeycloakAuthorization;
};

const {useGlobalState} = createGlobalState<GlobalState>({
  user: undefined,
  // accessToken: undefined,
  autClient: undefined,
  keycloakAuthorization: undefined,
});

function useUserData(): UserDataHook {
  const [user, setUser] = useGlobalState('user');
  // const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [autClient, setAuthClient] = useGlobalState('autClient');
  const [keycloakAuthorization, setKeycloakAuthorization] = useGlobalState('keycloakAuthorization');
  const {projectId} = useParams<{projectId: string}>();
  const {setPermissions, setAccessToken} = useAuthorizationStore.getState();

  // console.log("sdsdsds", autClient && autClient.token)

  const handleRefreshToken = useCallback(
    (autClient: Keycloak) => {
      // console.log("refresh", autClient)

      if (autClient) {
        autClient.updateToken(400).then((value: boolean) => {
          if (value) {
            authorizationToken.set(autClient.token);
            setAccessToken(autClient.token);
            // setAccessToken(autClient.token);
          }
        });
      }
    },
    [setAccessToken],
  );

  const authenticate = useCallback(async () => {
    const keycloak = new Keycloak({
      url: REACT_APP_KC_HOSTNAME,
      realm: REACT_APP_KC_REALM || '',
      clientId: REACT_APP_KC_CLIENT_ID || '',
    });
    const authenticatedResult = await keycloak.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      checkLoginIframe: false,
      silentCheckSsoFallback: false,
    });
    if (authenticatedResult) {
      setUser(keycloak.userInfo as User);
      setAccessToken(keycloak.token);
      authorizationToken.set(keycloak.token);
      setAuthClient(keycloak);
      const authorization = new KeycloakAuthorization(keycloak);
      setKeycloakAuthorization(authorization);

      const timeout = 5 * 60 * 1000;
      setInterval(() => {
        handleRefreshToken(keycloak);
      }, timeout);
    } else {
      keycloak.login();
    }
  }, [setUser, setAccessToken, setAuthClient, setKeycloakAuthorization, handleRefreshToken]);

  const accountManagement = useCallback(() => {
    if (!autClient) {
      return false;
    }
    return autClient.accountManagement();
  }, [autClient]);

  const logout = useCallback(() => {
    if (!autClient) {
      return false;
    }
    return autClient.logout({
      redirectUri: `https://${window.location.host}/`,
    });
  }, [autClient]);

  const getUserData = useCallback(async () => {
    if (autClient) {
      await autClient.loadUserInfo();
      setAccessToken(autClient.token);
      setUser(autClient.userInfo as User);
    }
  }, [autClient, setUser]);

  const authenticated = useCallback(() => {
    if (!autClient) {
      return false;
    }
    return autClient.authenticated;
  }, [autClient]);

  const updateUserPreferences = useCallback(
    (preferences: Partial<Preferences>) => {
      if (!user) {
        return;
      }
      setUser((prevUser: any) => {
        if (prevUser) {
          return {
            ...prevUser,
            preferences: {...prevUser.preferences, ...preferences},
          };
        }
        return null;
      });
    },
    [user, setUser],
  );

  // useEffect(() => {
  //   const timeout = 5 *  1000;
  //   setInterval(handleRefreshToken, timeout);
  // }, [handleRefreshToken]);

  const getUserRoles = useCallback((): Promise<Permission[]> => {
    return new Promise((resolve, reject) => {
      if (keycloakAuthorization) {
        keycloakAuthorization.entitlement(`x-${projectId}`).then(
          (rpt: any) => {
            // console.log('rpt', rpt);
            const decoded: {
              authorization: {
                permissions: Permission[];
              };
            } = jwt_decode(rpt);
            // console.log('decoded--', decoded);
            setPermissions(decoded.authorization.permissions);
            resolve(decoded.authorization.permissions);
            // return;
          },
          () => {
            return [];
          },
          () => {
            return [];
          },
        );
      }
      return new Promise((resolve, reject) => {
        return [];
      });
    });
  }, [keycloakAuthorization, projectId, setPermissions]);

  // useEffect(() => {
  //   const timeout = 5 * 60 * 1000;
  //   setInterval(handleRefreshToken, timeout);
  // }, [handleRefreshToken]);

  return {
    authenticated,
    getUserData,
    authenticate,
    getUserRoles,
    logout,
    accountManagement,
    user,
    setUser,
    accessToken: (autClient && autClient.token) || '',
    updateUserPreferences,
  };
}

export default useUserData;
